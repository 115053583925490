import * as PIXI from 'pixi.js';

import { FishKind, Variables } from '../../../config';
import { SpineInterface } from '../../../config/spine.generated';
import { AutoResizeTextStyle } from '../../components/autoResizeText';

export const fishToBaseAnim: Record<FishKind, SpineInterface['prize']['animations']> = {
  Rank1: 'pt01',
  Rank2: 'pt02',
  Rank3: 'pt03',
  Rank4: 'pt04',
  Rank5: 'pt05',
  Rank6: 'pt06',
  Rank7: 'pt07',
};

export const fishToRank: Record<FishKind, string> = {
  Rank1: 'AAAAA',
  Rank2: 'AAAA',
  Rank3: 'AAA',
  Rank4: 'AA',
  Rank5: 'A',
  Rank6: 'B',
  Rank7: 'C',
};

export const fishToMultiplier: Record<FishKind, string> = {
  Rank7: '10',
  Rank6: '30',
  Rank5: '70',
  Rank4: '150',
  Rank3: '400',
  Rank2: '1000',
  Rank1: '3000',
};

export const fishIconMultiplierBaseTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 304,
  fontSize: 24,
  fill: ['#FFF0A1', '#FFA200', '#FAFF00'],
  fillGradientStops: [0, 0.5, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowBlur: 0,
  dropShadowDistance: Math.sqrt(10 * 10),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.9,
};

export const fishIconMultiplierRank1TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};

export const fishIconMultiplierRank2TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};
export const fishIconMultiplierRank3TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};
export const fishIconMultiplierRank4TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};
export const fishIconMultiplierRank5TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};
export const fishIconMultiplierRank6TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};
export const fishIconMultiplierRank7TextStyle: Partial<AutoResizeTextStyle> = {
  ...fishIconMultiplierBaseTextStyle,
  fontSize: 106,
  fill: ['#FF5C00', '#FFA200'],
  fillGradientStops: [0.03, 1],
};

export const fishIconConfig: Record<
  FishKind,
  { textStyle: Partial<AutoResizeTextStyle>; pos: { x: number; y: number } }
> = {
  Rank1: {
    textStyle: fishIconMultiplierRank1TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
  Rank2: {
    textStyle: fishIconMultiplierRank2TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
  Rank3: {
    textStyle: fishIconMultiplierRank3TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
  Rank4: {
    textStyle: fishIconMultiplierRank4TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
  Rank5: {
    textStyle: fishIconMultiplierRank5TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
  Rank6: {
    textStyle: fishIconMultiplierRank6TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
  Rank7: {
    textStyle: fishIconMultiplierRank7TextStyle,
    pos: { x: 619 + 304 / 2 + 137 - 1210 / 2, y: 656 + 144 / 2 + 59 - 1056 / 2 },
  },
};
