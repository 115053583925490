import { TrackEntry } from '@pixi-spine/all-4.1';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import ViewContainer from '../components/container';
import { layerFishingGambleBack } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

type FishingGambleActionState = 'wait' | 'start' | 'climax1' | 'climax2' | 'lose';

class FishingGambleAllScreen extends ViewContainer {
  private startSpine: TickerSpine<'fg_indi'>;
  private climaxA_bg: TickerSpine<'fg_indi_crymax'>;
  private climaxB_bg: TickerSpine<'fg_indi_crymax'>;
  private climaxC_bg: TickerSpine<'fg_indi_crymax'>;
  private climaxA: TickerSpine<'fg_indi_crymax'>;
  private climaxB: TickerSpine<'fg_indi_crymax'>;
  private climaxC: TickerSpine<'fg_indi_crymax'>;
  private climax_line: TickerSpine<'fg_indi_crymax'>;

  private layout: 'portrait' | 'landscape';
  private stopEventName: string;
  private fishKind: FishKind;

  private state: FishingGambleActionState = 'wait';

  constructor() {
    super();

    this.fishKind = 'Rank1';
    this.layout = 'landscape';
    this.stopEventName = '';

    this.startSpine = new TickerSpine('fg_indi');

    this.climaxA_bg = new TickerSpine('fg_indi_crymax');
    this.climaxB_bg = new TickerSpine('fg_indi_crymax');
    this.climaxC_bg = new TickerSpine('fg_indi_crymax');
    this.climaxA = new TickerSpine('fg_indi_crymax');
    this.climaxB = new TickerSpine('fg_indi_crymax');
    this.climaxC = new TickerSpine('fg_indi_crymax');
    this.climax_line = new TickerSpine('fg_indi_crymax');

    this.addChild(
      this.startSpine,
      this.climaxA_bg,
      this.climaxB_bg,
      this.climaxC_bg,
      this.climaxA,
      this.climaxB,
      this.climaxC,
      this.climax_line,
    );

    eventManager.on(EventTypes.GAMBLE_SELECTED, this.setFishKind.bind(this));
    eventManager.on(EventTypes.FISHING_GAMBLE_START, this.onGambleIconClick.bind(this));
    eventManager.on(EventTypes.FISHING_GAMBLE_LOSE, this.onLose.bind(this));

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.initSpineListener();

    //this.parentLayer = layerFishingPrizeBg;

    this.startSpine.parentLayer = layerFishingGambleBack;
    this.climaxA_bg.parentLayer = layerFishingGambleBack;
    this.climaxB_bg.parentLayer = layerFishingGambleBack;
    this.climaxC_bg.parentLayer = layerFishingGambleBack;
    this.climaxA.parentLayer = layerFishingGambleBack;
    this.climaxB.parentLayer = layerFishingGambleBack;
    this.climaxC.parentLayer = layerFishingGambleBack;
    this.climax_line.parentLayer = layerFishingGambleBack;

    this.setActionVisible('wait');
    this.visible = false;
  }

  private initSpineListener() {
    this.startSpine.state.addListener({
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name === 'start') {
          this.climaxA_bg.state.setAnimation(0, 'a_bg', false);
          this.climaxA.state.setAnimation(0, 'a', false);

          this.state = 'climax1';
          this.setActionVisible('climax1');

          eventManager.emit(EventTypes.FISHING_GAMBLE_METER_START);
        }
      },
    });

    this.climaxA.state.addListener({
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name === 'a') {
          this.climaxB_bg.state.setAnimation(0, 'b_bg', false);
          this.climaxB.state.setAnimation(0, 'b', false);
          this.climax_line.state.addAnimation(0, 'syutyu', true);

          this.state = 'climax2';
          this.setActionVisible('climax2');
        }
      },
    });
  }

  private onGambleIconClick() {
    this.visible = true;
    this.startSpine.state.setAnimation(0, 'start', false);
    this.setActionVisible('start');
  }

  private setActionVisible(state: FishingGambleActionState) {
    this.startSpine.visible = false;
    this.climaxA_bg.visible = false;
    this.climaxA.visible = false;
    this.climaxB_bg.visible = false;
    this.climaxB.visible = false;
    this.climaxC.visible = false;
    this.climaxC_bg.visible = false;
    this.climax_line.visible = false;

    switch (state) {
      case 'start': {
        this.startSpine.visible = true;
        break;
      }
      case 'climax1': {
        this.climaxA_bg.visible = true;
        this.climaxA.visible = true;
        break;
      }
      case 'climax2': {
        this.climaxB_bg.visible = true;
        this.climaxB.visible = true;
        this.climax_line.visible = true;
        break;
      }
      case 'lose': {
        this.climaxC.visible = true;
        this.climaxC_bg.visible = true;
        this.climax_line.visible = true;
        break;
      }
      default:
      case 'wait': {
        break;
      }
    }
  }

  private setFishKind(fishKind?: FishKind) {
    const RankToClimaxSkin: Record<FishKind, SpineInterface['fg_indi_crymax']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };
    const RankToStartSkin: Record<FishKind, SpineInterface['fg_indi']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    this.fishKind = fishKind ?? 'Rank7';

    this.startSpine.skeleton.setSkinByName(RankToStartSkin[this.fishKind]);

    this.climaxA.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
    this.climaxA_bg.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
    this.climaxB.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
    this.climaxB_bg.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
    this.climaxC.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
    this.climaxC_bg.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
  }
  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    if (settings.mode === GameMode.FISH_GAMBLE) {
      this.state = 'wait';
      this.setActionVisible('wait');
      this.visible = false;
    } else {
      this.visible = false;
    }
  }

  private onLose() {
    this.climaxC_bg.state.setAnimation(0, 'c_bg', false);
    this.climaxC.state.setAnimation(0, 'c', false);

    this.state = 'lose';
    this.setActionVisible('lose');
  }
  private applicationResize = (width: number, height: number): void => {
    //const layout = width > height ? FishingLayout.Gamble.landscape : FishingLayout.Gamble.portrait;
    //const scale = layout.scale;

    this.x = width / 2;
    this.y = height / 2;

    //this.startSpine.position.set(layout.start.x, layout.start.y);

    if (width > height) {
      this.layout = 'landscape';
    } else {
      this.layout = 'portrait';
    }

    const bgSize = { width: 2000, height: 2000 };
    const bgAspectRatio = bgSize.width / bgSize.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / bgSize.height;
    } else {
      scale = width / bgSize.width;
    }

    this.startSpine.scale.set(scale);
    this.climaxA_bg.scale.set(scale);
    this.climaxA.scale.set(scale);
    this.climaxB_bg.scale.set(scale);
    this.climaxB.scale.set(scale);
    this.climaxC.scale.set(scale);
    this.climaxC_bg.scale.set(scale);
    this.climax_line.scale.set(scale);
  };
}

export default FishingGambleAllScreen;
