import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <h1 className={styles.title}>{t('infoPayLinesTitle')}</h1>
      <div className={`${styles.p} ${styles.center}`}>{t('infoPayLinesDesc')}</div>
      <div className={styles.payLines}>
        <div className={styles['image-container']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.infoPayline)} alt="payLines" />
        </div>
      </div>
    </section>
  );
};

export default PayLines;
