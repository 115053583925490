import * as PIXI from 'pixi.js';

import { BaseMessageBannerProps } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql/cache';
import ViewContainer from '../components/container';
import { layerBanner, layerBannerBlackBoard } from '../components/layers/layers';
import { EventTypes, eventManager } from '../config';

export class BaseMessageBanner extends ViewContainer {
  //protected banner?: PIXI.Sprite;

  protected props: BaseMessageBannerProps;

  protected bindedHandleDestroy = this.handleDestroy.bind(this);

  protected window: PIXI.ISize = { width: 0, height: 0 };

  constructor(props: BaseMessageBannerProps) {
    super();
    this.visible = true;
    this.interactive = true;

    setIsOpenedMessageBanner(true);

    this.props = props;

    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.5;
    backdrop.width = 5000;
    backdrop.height = 5000;
    this.addChild(backdrop);

    backdrop.parentLayer = layerBannerBlackBoard;

    this.parentLayer = layerBanner;
  }

  protected init(): BaseMessageBanner {
    this.on('click', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);

    if (this.props.onInitCallback) {
      this.props.onInitCallback();
    }

    eventManager.addListener(EventTypes.RESIZE, this.bindedApplicationResize);
    return this;
  }

  public handleDestroy(): void {
    setIsOpenedMessageBanner(false);
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedApplicationResize);

    this.destroy({ children: true });
  }

  private applicationResize = (width: number, height: number): void => {
    this.handleResize(width, height);
  };

  public handleResize(_width: number, _height: number): void {
    /*
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      //      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
      */
  }
  protected bindedApplicationResize = this.applicationResize.bind(this);
}
