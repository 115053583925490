import { TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { FishKind } from '../../config/config';
import { SpineInterface } from '../../config/spine.generated';
import { setIsFishingUserActionWait } from '../../gql/cache';
//import Tween from '../animations/tween';
import ButtonContainer from '../components/buttonContainer';
//import ViewContainer from '../components/container';
import { layerBanner } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

const rouletteNmlConver: Record<FishKind, SpineInterface['prize']['animations'][]> = {
  Rank1: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt01'],
  Rank2: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_d1', 'roule_last_pt02'],
  Rank3: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt03'],
  Rank4: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_d1', 'roule_last_pt04'],
  Rank5: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt05'],
  Rank6: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_d1', 'roule_last_pt06'],
  Rank7: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt07'],
};
const rouletteSPConver: Record<FishKind, SpineInterface['prize']['animations'][]> = {
  Rank1: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt01'],
  Rank2: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_d2', 'roule_last_pt02'],
  Rank3: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt03'],
  Rank4: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_d2', 'roule_last_pt04'],
  Rank5: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt05'],
  Rank6: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_d2', 'roule_last_pt06'],
  Rank7: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt07'],
};

class FishRoulette extends ButtonContainer {
  private rouletteBase: TickerSpine<'prize'>;
  private roulette: TickerSpine<'prize'>;

  private fishKind: FishKind;
  private title: TickerSpine<'prize'>;

  private isSuperBuyFeature: boolean;

  private isSpin: boolean;

  private iconContainer: PIXI.Container;

  constructor() {
    super(-400, -400, 800, 800, () => this.skipRoulette());

    this.fishKind = 'Rank7';
    this.isSuperBuyFeature = false;
    this.isSpin = false;

    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.5;
    backdrop.width = 5000;
    backdrop.height = 5000;
    this.addChild(backdrop);

    this.rouletteBase = new TickerSpine('prize');
    this.rouletteBase.state.setEmptyAnimation(0);

    this.roulette = new TickerSpine('prize');
    this.roulette.state.setEmptyAnimation(0);

    this.iconContainer = new PIXI.Container();
    this.iconContainer.addChild(this.rouletteBase, this.roulette);

    this.title = new TickerSpine('prize');
    this.title.state.setAnimation(0, 'title', true);

    eventManager.addListener(EventTypes.FISHING_START_ROULETTE, this.startRoulette.bind(this));
    eventManager.addListener(EventTypes.FISHING_SKIP_ROULETTE, this.skipRoulette.bind(this));
    eventManager.on(EventTypes.RESIZE, this.handleResize.bind(this));

    this.addChild(this.iconContainer, this.title);

    this.visible = false;
    this.parentLayer = layerBanner;
  }

  private startRoulette(fishKind: FishKind) {
    setIsFishingUserActionWait(true);
    this.fishKind = fishKind;
    this.isSpin = true;
    const animTbl = this.isSuperBuyFeature ? rouletteSPConver[this.fishKind] : rouletteNmlConver[this.fishKind];

    this.rouletteBase.state.setAnimation(0, 'roule_base', true);
    this.roulette.state.setAnimation(0, animTbl[0]!, false);
    this.roulette.state.addAnimation(0, animTbl[1]!, false);
    this.roulette.state.addAnimation(0, animTbl[2]!, false);
    this.roulette.state.addAnimation(0, animTbl[3]!, false);
    this.roulette.state.addAnimation(0, animTbl[4]!, false);

    this.roulette.state.addListener({
      complete: (entry: TrackEntry) => {
        //called multi count why;
        if (entry.animation?.name.includes('roule_last') && this.isSpin) {
          this.isSpin = false;
          //const delay = Tween.createDelayAnimation(300);
          //delay.addOnComplete(() => {
          this.endRoulette();
          //});
          //delay.start();
        }
      },
    });
    eventManager.emit(EventTypes.FISHING_ROULETTE_START);
    this.visible = true;
    this.interactive = true;
    this.buttonMode = true;

    AudioApi.play({ type: ISongs.XT005S_challenge_roulette, stopPrev: true });
  }
  private skipRoulette() {
    //this.roulette.state.setEmptyAnimation(0);
    if (this.isSpin) {
      this.endRoulette();
    }
  }

  private endRoulette() {
    this.isSpin = false;
    this.roulette.state.setEmptyAnimation(0);
    setIsFishingUserActionWait(false);
    this.interactive = false;
    eventManager.emit(EventTypes.FISHING_START_BANNER);
    this.visible = false;
    AudioApi.stop({ type: ISongs.XT005S_challenge_roulette, stopPrev: true });
    AudioApi.play({ type: ISongs.XT005S_challenge_roulette_stop, stopPrev: true });
  }

  public handleResize(width: number, height: number): void {
    const layoutBase = width > height ? gameLayout.fishingRoulette.landscape : gameLayout.fishingRoulette.portrait;

    this.title.position.copyFrom(layoutBase.title);
    this.title.scale.set(layoutBase.title.scale);
    this.iconContainer.position.copyFrom(layoutBase.icon);
    this.iconContainer.scale.set(layoutBase.icon.scale);
    /*
    if (height > width) {
      this.x = FishingLayout.Banner.Portrait.Base.x;
      this.y = FishingLayout.Banner.Portrait.Base.y;
      this.title.x = FishingLayout.Banner.Portrait.title.x;
      this.title.y = FishingLayout.Banner.Portrait.title.y;
      this.title.scale.set(FishingLayout.Banner.Portrait.titleScale);
      this.scale.set(FishingLayout.Banner.Portrait.BaseScale);
    } else {
      this.x = FishingLayout.Banner.LandScape.Base.x;
      this.y = FishingLayout.Banner.LandScape.Base.y;

      this.title.x = FishingLayout.Banner.LandScape.title.x;
      this.title.y = FishingLayout.Banner.LandScape.title.y;
      this.title.scale.set(FishingLayout.Banner.LandScape.titleScale);
      this.scale.set(FishingLayout.Banner.LandScape.BaseScale);
    }
      */
  }
}
export default FishRoulette;
