export enum BonusKind {
  FREE_SPINS = 'FreeSpins',
  BUY_FEATURE_NML = 'BuyFeatureNml',
  BUY_FEATURE_SP = 'BuyFeatureSp',
}

export type FreespinBonusInfo = {
  bonusKind: BonusKind.FREE_SPINS;
};

export type BuyFeatureBonusInfo = {
  bonusKind: BonusKind.BUY_FEATURE_NML | BonusKind.BUY_FEATURE_SP;
};

//TO DO
export const BUY_FEATURE_BONUS_ID_NML = 'buynml9e-4eff-42c2-9078-65154780d6e2';
export const BUY_FEATURE_BONUS_ID_SP = 'busp09e-4eff-42c2-9078-65154780d6e2';
export const BonusDataByBonusId: Record<string, FreespinBonusInfo | BuyFeatureBonusInfo> = {
  'f43bb43d-79d3-4d49-aad7-b395a1e4fc2a': {
    bonusKind: BonusKind.FREE_SPINS,
  },
  [BUY_FEATURE_BONUS_ID_NML]: {
    bonusKind: BonusKind.BUY_FEATURE_NML,
  },
  [BUY_FEATURE_BONUS_ID_SP]: {
    bonusKind: BonusKind.BUY_FEATURE_SP,
  },
};
