import { FishKind } from '../config';
import i18n from '../i18next';
import { fishToMultiplier } from '../slotMachine/fishing/icon/config';

export const getFishRankTitleText = (): string => {
  return i18n.t('fishing.challenge.meter.title');
};

export const getFishRankText = (fishKind: FishKind): string => {
  const rankToRankText: Record<FishKind, string> = {
    Rank1: i18n.t('fishing.challenge.meter.rank1.rank'),
    Rank2: i18n.t('fishing.challenge.meter.rank2.rank'),
    Rank3: i18n.t('fishing.challenge.meter.rank3.rank'),
    Rank4: i18n.t('fishing.challenge.meter.rank4.rank'),
    Rank5: i18n.t('fishing.challenge.meter.rank5.rank'),
    Rank6: i18n.t('fishing.challenge.meter.rank6.rank'),
    Rank7: i18n.t('fishing.challenge.meter.rank7.rank'),
  };
  return rankToRankText[fishKind];
};

export const getFishNameText = (fishKind: FishKind): string => {
  const rankToNameText: Record<FishKind, string> = {
    Rank1: i18n.t('fishing.challenge.meter.rank1.name'),
    Rank2: i18n.t('fishing.challenge.meter.rank2.name'),
    Rank3: i18n.t('fishing.challenge.meter.rank3.name'),
    Rank4: i18n.t('fishing.challenge.meter.rank4.name'),
    Rank5: i18n.t('fishing.challenge.meter.rank5.name'),
    Rank6: i18n.t('fishing.challenge.meter.rank6.name'),
    Rank7: i18n.t('fishing.challenge.meter.rank7.name'),
  };
  return rankToNameText[fishKind];
};

export const getFishDescText = (fishKind: FishKind): string => {
  return i18n.t('fishing.challenge.meter.desc', { multiplier: fishToMultiplier[fishKind] });
};
