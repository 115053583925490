import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { setCurrency } from '../../gql/cache';
import { getBetsSetting } from '../../gql/fromFragment';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoGameRules' });
  const currency = useReactiveVar(setCurrency);
  const betSettings = getBetsSetting();
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });

  return (
    <section>
      <h1 className={styles.title}>{t('Title')}</h1>

      <div className={`${styles.p}`}>
        {t('Rules', { returnObjects: true }).map((str, idx) => (
          <div key={idx} dangerouslySetInnerHTML={{ __html: str }} />
        ))}
      </div>
      <div className={`${styles.p}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('Malfunction1'),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('Malfunction2'),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('Malfunction3'),
          }}
        />
      </div>
      <br />
      <div className={`${styles.p} ${styles.center}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('Min', {
              minBet,
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('Max', {
              maxBet,
            }),
          }}
        />
      </div>
      <br></br>
      <div className={`${styles.p} ${styles.center}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('RTP', {
              rtp: '##.##%',
            }),
          }}
        />
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeature', { rtp: '##.##%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('NormalBait', { rtp: '##.##%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('SuperBait', { rtp: '##.##%' }) }} />
      </div>
      <div
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('Version', {
            version: global.__ENV__.APP_VERSION,
          }),
        }}
      />
    </section>
  );
};

export default GameRules;
