import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { Colors, ISongs } from '../../config';
import { ResourceTypes } from '../../resources';

import ButtonContainer from './buttonContainer';

export type IButtonTexture = {
  default: ResourceTypes | string;
  hover: ResourceTypes | string;
  press: ResourceTypes | string;
  disable: ResourceTypes | string;
};

export class SpriteButton extends PIXI.Sprite {
  private _disable: boolean;

  private textures: IButtonTexture;

  private isPointerDown = false;

  constructor(textures: IButtonTexture, onClick?: PIXI.utils.EventEmitter.ListenerFn) {
    super(PIXI.Texture.from(textures.default));

    this.textures = textures;
    this._disable = false;
    this.interactive = true;
    this.buttonMode = true;
    this.anchor.set(0.5);

    this.on('pointerdown', () => {
      this.isPointerDown = true;
      this.texture = PIXI.Texture.from(textures.press);
    });
    this.on('mouseover', (_e: string) => {
      if (this.isPointerDown) {
        return;
      }
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.texture = PIXI.Texture.from(textures.hover);
    });
    this.on('pointerupoutside', () => {
      this.isPointerDown = false;
      this.texture = PIXI.Texture.from(textures.default);
    });
    this.on('pointerout', () => {
      if (this.isPointerDown) {
        return;
      }
      this.texture = PIXI.Texture.from(textures.default);
    });
    this.on('pointertap', (ev: PIXI.InteractionEvent) => {
      this.isPointerDown = false;
      this.texture = PIXI.Texture.from(textures.default);
      if (ev.data.isPrimary) onClick?.call(null);
    });
  }

  public set disable(value: boolean) {
    this._disable = value;
    this.interactive = !value;
    this.buttonMode = !value;

    if (value) {
      this.texture = PIXI.Texture.from(this.textures.disable);
    } else {
      this.texture = PIXI.Texture.from(this.textures.default);
    }
  }

  public get disable(): boolean {
    return this._disable;
  }
}

export class SpriteInteractiveButton extends PIXI.Container {
  private isDisabled: boolean;

  private btn: PIXI.Sprite;

  private scaleContainer: PIXI.Container;
  public btnContainer: ButtonContainer;

  private intent: string;

  private isGameColor: boolean;

  private defaultScale: number;

  constructor(
    textureName: string,
    isGameColor: boolean,
    defaultScale: number,
    onClick?: PIXI.utils.EventEmitter.ListenerFn,
  ) {
    super();

    this.intent = textureName;
    this.isGameColor = isGameColor;
    this.isDisabled = false;
    this.defaultScale = defaultScale;

    this.btn = this.initBtn();

    this.btnContainer = new ButtonContainer(
      (-this.btn.width * this.defaultScale) / 2,
      (-this.btn.height * this.defaultScale) / 2,
      this.btn.width * this.defaultScale,
      this.btn.height * this.defaultScale,
      () => {
        if (!this.isDisabled && onClick) {
          onClick();
        }
      },
      this.mouseOver.bind(this),
      this.mouseOut.bind(this),
      this.mouseDown.bind(this),
      this.mouseUp.bind(this),
    );
    this.btnContainer.addChild(this.btn);
    this.scaleContainer = new PIXI.Container();
    this.scaleContainer.scale.set(this.defaultScale);
    this.scaleContainer.addChild(this.btnContainer);

    this.addChild(this.scaleContainer);
  }
  private initBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(`${this.intent}`));
    btn.anchor.set(0.5);
    if (this.isGameColor) {
      btn.tint = Colors.GAME_COLOR;
    }
    return btn;
  }

  public setDisable(disable: boolean) {
    this.handleDisable(disable);
  }
  private onClick() {}

  private mouseOver() {
    if (!this.isDisabled) {
      //      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.scaleContainer.scale.set(1.1 * this.defaultScale);
      this.scaleContainer.position.set(0, 0);
    }
  }
  private mouseOut() {
    if (!this.isDisabled) {
      this.scaleContainer.scale.set(1.0 * this.defaultScale);
      this.scaleContainer.position.set(0, 0);
    }
  }
  private mouseDown() {
    if (!this.isDisabled) {
      this.scaleContainer.scale.set(0.88 * this.defaultScale);
      this.scaleContainer.position.set(0, 10);
    }
  }
  private mouseUp() {
    if (!this.isDisabled) {
      this.scaleContainer.scale.set(1.0 * this.defaultScale);
      this.scaleContainer.position.set(0, 0);
    }
  }

  private handleDisable(disable: boolean): void {
    this.isDisabled = disable;
    this.btnContainer.buttonMode = !disable;

    if (disable) {
      this.btn.alpha = 0.5;
      this.scaleContainer.scale.set(1.0 * this.defaultScale);
      this.interactive = false;
    } else {
      this.btn.alpha = 1;
      this.interactive = true;
    }
  }
}
