import * as PIXI from 'pixi.js';

import { GameMode } from '../../consts';
import { MessageFreeSpinEndBannerProps, MessageFreeSpinsBannerProps } from '../../global.d';
import { isFishingChallengeMode } from '../../utils';
import ViewContainer from '../components/container';
import {
  EventTypes,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  GameViewState,
  REELS_AMOUNT,
  SLOT_HEIGHT,
  SLOT_REELMASK_X,
  SLOT_REELMASK_Y,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import FishingChallenge from '../fishing/fishingChallenge';
import FishGamble from '../fishing/fishingGamble';
import FishPrize from '../fishing/fishingPrize';
import FishOn from '../fishing/fishon';
import GambleSelect from '../fishing/gambleSelect';
import FishRoulette from '../fishing/roulette';
import FishScaling from '../fishing/scaling';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import MessageWindow from '../messageWindow/messageWindow';
import { REELS_SETTING } from '../reels/config';
import ReelsFrame from '../reels/frame/reelsFrame';

import { isFreeSpinsMode } from './../../utils/conditions';
import GameTitle from './gameTitle';
import Rod from './rod';

class GameView extends ViewContainer {
  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainers: PIXI.Container[] = [];

  public reelTintContainer: PIXI.Container;

  public slotsContainers: PIXI.Container[] = [];

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public messageWindow: PIXI.Container;

  public reelsFrame: ReelsFrame;

  public announceContainer: PIXI.Container;

  public linesContainer: PIXI.Container;

  public slotsAllContainer: PIXI.Container;

  public rod: Rod;

  private window: PIXI.ISize = { width: 0, height: 0 };

  private fishRoulette: FishRoulette;

  private fishingChallenge: FishingChallenge;

  private fishingPrize: FishPrize;

  private fishingGamble: FishGamble;

  private gambleSelect: GambleSelect;

  private fishingScaling: FishScaling;

  private fishOn: FishOn;

  constructor(props: IGameContainer) {
    super();

    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsAllContainer = new PIXI.Container();

    //this.slotsContainers = new PIXI.Container();
    this.gameTitle = new GameTitle();
    this.messageWindow = new MessageWindow();
    this.reelsFrame = new ReelsFrame();
    this.rod = new Rod();
    this.fishingChallenge = new FishingChallenge();
    this.fishingPrize = new FishPrize();
    this.fishingGamble = new FishGamble();
    this.gambleSelect = new GambleSelect();
    this.fishingScaling = new FishScaling();
    this.fishRoulette = new FishRoulette();
    this.fishOn = new FishOn();

    this.winLabelContainer = props.winLabelContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.reelTintContainer = props.reelTintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainers = props.reelsContainers;
    this.winCountUpMessage = props.winCountUpMessage;
    this.announceContainer = props.announceContainer;
    this.linesContainer = props.linesContainer;

    this.slotStopDisplayContainer.addChild(this.linesContainer);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.slotsContainers[i] = new PIXI.Container();
      if (this.slotsContainers[i] != undefined) {
        this.slotsContainers[i]?.addChild(this.reelTintContainer);
        this.slotsContainers[i]?.addChild(this.reelsContainers[i]!);
        //        this.slotsContainers[i]?.position.set(Math.floor(i / 2) * SLOT_WIDTH, (i % 2) * SLOT_HEIGHT);
        this.slotsContainers[i]?.position.set((i % 2) * SLOT_WIDTH, Math.floor(i / 2) * SLOT_HEIGHT);

        //2ko demo ikeru
        const mask = new PIXI.Graphics();
        mask.beginFill(0xffffff);

        mask.drawRect(SLOT_REELMASK_X, SLOT_REELMASK_Y, SLOT_WIDTH, SLOT_HEIGHT);
        mask.endFill();
        const layer = REELS_SETTING[i] ? REELS_SETTING[i]?.parentLayer : REELS_SETTING[0]?.parentLayer;
        if (layer) {
          layer.mask = mask;
          mask.parentLayer = layer;
          this.slotsContainers[i]?.addChild(mask);
        }

        this.slotsAllContainer.addChild(this.slotsContainers[i]!);
      }
    }

    this.addChild(this.gameTitle);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsAllContainer);
    this.addChild(this.slotStopDisplayContainer);
    this.addChild(this.announceContainer);
    this.addChild(this.reelsFrame);
    this.addChild(this.fishOn);
    this.addChild(this.rod);
    this.addChild(this.fishingChallenge);
    this.addChild(this.messageWindow);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.fishingPrize);
    this.addChild(this.fishingGamble);
    this.addChild(this.gambleSelect);
    this.addChild(this.fishingScaling);
    this.addChild(this.fishRoulette);

    eventManager.addListener(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsStartMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createFreeSpinEndMessage.bind(this));
    eventManager.addListener(EventTypes.SET_FREESPINS_VIEW, this.setFreeSpinsView.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {
    //to base
    if (settings.mode === GameMode.BASE_GAME) {
      this.setBaseGameView();

      //TO DO
      this.gameTitle.visible = true;
      this.reelsBackgroundContainer.visible = true;
      this.slotsAllContainer.visible = true;
      this.slotStopDisplayContainer.visible = true;
      this.announceContainer.visible = true;
      this.reelsFrame.visible = true;
    } else if (isFishingChallengeMode(settings.mode)) {
      this.gameTitle.visible = false;
      this.reelsBackgroundContainer.visible = false;
      this.slotsAllContainer.visible = false;
      this.slotStopDisplayContainer.visible = false;
      this.announceContainer.visible = false;
      this.reelsFrame.visible = false;
    } else if (isFreeSpinsMode(settings.mode)) {
      this.gameTitle.visible = false;
      this.reelsBackgroundContainer.visible = false;
      this.slotsAllContainer.visible = false;
      this.slotStopDisplayContainer.visible = false;
      this.announceContainer.visible = false;
      this.reelsFrame.visible = false;
    }
  }

  private setBaseGameView() {
    eventManager.emit(EventTypes.HIDE_COINS);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);

    this.resetBasePositionByState('Base');
  }

  private setFreeSpinsView() {
    this.resetBasePositionByState('Base');
  }

  private createFreeSpinsStartMessage(props: MessageFreeSpinsBannerProps) {
    this.setFreeSpinsView();
    const messageBanner = new MessageFreeSpinsBanner(props);
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private createFreeSpinEndMessage(_props: MessageFreeSpinEndBannerProps): void {}

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private resetBasePositionByState(state: GameViewState) {
    switch (state) {
      case 'Base':
        break;
      default:
        break;
    }
  }
}

export default GameView;
