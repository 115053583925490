import { Group, Layer } from '@pixi/layers';
import * as PIXI from 'pixi.js';

export const layerBackGround = new Layer(new Group(0));
export const layerRod = new Layer(new Group(4));
export const layerGameTitle = new Layer(new Group(5));
export const layerReelBack = new Layer(new Group(10));
export const layerReelBackFront = new Layer(new Group(11));
export const layerReel0 = new Layer(new Group(12));
export const layerReel1 = new Layer(new Group(13));
export const layerReel2 = new Layer(new Group(14));
export const layerReel3 = new Layer(new Group(15));

export const layerReel = new Layer(new Group(15));
export const layerReelFront = new Layer(new Group(16));
export const layerReelFrame = new Layer(new Group(19));
export const layerReelFrameFront = new Layer(new Group(20));
export const layerAnnounce = new Layer(new Group(23));

export const layerFishingChallenge = new Layer(new Group(24));
export const layerFishingGambleBack = new Layer(new Group(25));
export const layerFishingGamble = new Layer(new Group(26));
export const layerFishingGambleFront = new Layer(new Group(27));
export const layerFishingScaling = new Layer(new Group(54));

export const layerBuyFeatureBtn = new Layer(new Group(30));
export const layerWin = new Layer(new Group(40));
export const layerBannerBlackBoard = new Layer(new Group(49));
export const layerBanner = new Layer(new Group(51));
export const layerUI = new Layer(new Group(55));
export const layerBuyFeatureBg = new Layer(new Group(59));
export const layerFishingPrizeBg = new Layer(new Group(59));
export const layerFishingPrize = new Layer(new Group(60));
export const layerFishingGambleSelect = new Layer(new Group(61));
export const layerBuyFeaturePopUp = new Layer(new Group(60));
export const layerMessageWindow = new Layer(new Group(61));
export const layerMenu = new Layer(new Group(65));
export const layerAllScreen = new Layer(new Group(70));
//export const layerSlotMachine = new Layer(new Group(80));
export const layerIntroScreen = new Layer(new Group(90));
export const layerTransition = new Layer(new Group(99));

export const InitLayer = (application: PIXI.Application): void => {
  layerReel.group.enableSort = true;

  layerBanner.group.enableSort = true;

  application.stage.addChild(layerBackGround);
  application.stage.addChild(layerGameTitle);
  application.stage.addChild(layerRod);
  application.stage.addChild(layerReelBack);
  application.stage.addChild(layerReelBackFront);
  application.stage.addChild(layerReel0);
  application.stage.addChild(layerReel1);
  application.stage.addChild(layerReel2);
  application.stage.addChild(layerReel3);
  application.stage.addChild(layerReel);
  application.stage.addChild(layerReelFront);
  application.stage.addChild(layerReelFrame);
  application.stage.addChild(layerReelFrameFront);
  application.stage.addChild(layerFishingChallenge);
  application.stage.addChild(layerFishingPrizeBg);
  application.stage.addChild(layerFishingPrize);
  application.stage.addChild(layerFishingGambleSelect);
  application.stage.addChild(layerFishingScaling);

  application.stage.addChild(layerBannerBlackBoard);
  application.stage.addChild(layerBanner);
  application.stage.addChild(layerMessageWindow);
  application.stage.addChild(layerAnnounce);
  application.stage.addChild(layerWin);
  application.stage.addChild(layerUI);
  application.stage.addChild(layerBuyFeatureBtn);
  application.stage.addChild(layerBuyFeatureBg);
  application.stage.addChild(layerBuyFeaturePopUp);
  application.stage.addChild(layerMenu);
  application.stage.addChild(layerAllScreen);
  //  application.stage.addChild(layerSlotMachine);
  application.stage.addChild(layerIntroScreen);
  application.stage.addChild(layerTransition);
};
