import { Container } from 'pixi.js';

import { MessageFreeSpinsBannerProps } from '../../global';
import { layerMessageWindow } from '../components/layers/layers';
import { TickerSpine } from '../components/spine/index';
import { gameLayout } from '../config';
import { FishIcon } from '../fishing/icon/fishIcon';

import { BaseMessageBanner } from './baseMessageBanner';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  private icon: FishIcon;
  private title: TickerSpine<'prize'>;
  private bannerContainer: Container;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;

    this.icon = new FishIcon(this.props.fishRank);

    this.title = new TickerSpine('prize');
    this.title.state.setAnimation(0, 'title', true);

    this.bannerContainer = new Container();
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();

    this.bannerContainer.addChild(this.icon, this.title);

    this.addChild(this.bannerContainer);

    this.icon.visible = true;
    this.interactive = true;
    this.buttonMode = true;

    this.title.parentLayer = layerMessageWindow;
    return this;
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);

    const layoutBase = width > height ? gameLayout.fishingRoulette.landscape : gameLayout.fishingRoulette.portrait;

    this.title.position.copyFrom(layoutBase.title);
    this.title.scale.set(layoutBase.title.scale);
    this.icon.position.copyFrom(layoutBase.icon);
    this.icon.scale.set(layoutBase.icon.scale);

    /*
    if (height > width) {
      this.x = FishingLayout.Banner.Portrait.Base.x;
      this.y = FishingLayout.Banner.Portrait.Base.y;

      this.title.x = FishingLayout.Banner.Portrait.title.x;
      this.title.y = FishingLayout.Banner.Portrait.title.y;

      this.title.scale.set(FishingLayout.Banner.Portrait.titleScale);

      this.scale.set(FishingLayout.Banner.Portrait.BaseScale);
    } else {
      this.x = FishingLayout.Banner.LandScape.Base.x;
      this.y = FishingLayout.Banner.LandScape.Base.y;

      this.title.x = FishingLayout.Banner.LandScape.title.x;
      this.title.y = FishingLayout.Banner.LandScape.title.y;

      this.title.scale.set(FishingLayout.Banner.LandScape.titleScale);

      this.scale.set(FishingLayout.Banner.LandScape.BaseScale);
    }
      */
  }
}
