import { FishKind } from '../../../config';
import i18n from '../../../i18next';
import AutoResizeText from '../../components/autoResizeText';
import ViewContainer from '../../components/container';
import { TickerSpine } from '../../components/spine';
import {
  FREESPIN_START_BANNER_DESCRIPTION_POS,
  FREESPIN_START_BANNER_RANK_POS,
  FREESPIN_START_BANNER_RANK_TITLE_POS,
} from '../../messageBanner/config';
import {
  freeSpinStartBannerDescriptionStyles,
  freeSpinStartBannerRankStyles,
  freeSpinStartBannerRankTitleStyles,
} from '../../messageBanner/textStyles';

import { fishIconConfig, fishToBaseAnim, fishToMultiplier, fishToRank } from './config';

export class FishIcon extends ViewContainer {
  private base: TickerSpine<'prize'>;

  private rankTitle: AutoResizeText;
  private rank: AutoResizeText;

  private description: AutoResizeText;
  private multiplier: AutoResizeText;

  private fishKind: FishKind;

  constructor(fishKind: FishKind) {
    super();

    this.fishKind = fishKind;

    this.base = new TickerSpine('prize');
    this.base.state.setAnimation(0, 'front', true);
    this.base.state.setAnimation(1, fishToBaseAnim[this.fishKind], true);

    this.rankTitle = this.initRankTitle();
    this.rank = this.initRank();
    this.description = this.initDescription();
    this.multiplier = this.initMultiplier();

    this.addChild(this.base, this.rankTitle, this.rank, this.description, this.multiplier);
  }

  private initRankTitle(): AutoResizeText {
    const autoResizeText = new AutoResizeText(
      i18n.t('freeSpinStartBanner.rankTitle'),
      freeSpinStartBannerRankTitleStyles,
    );
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_RANK_TITLE_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_RANK_TITLE_POS.y;
    return autoResizeText;
  }

  private initRank(): AutoResizeText {
    const autoResizeText = new AutoResizeText(fishToRank[this.fishKind], freeSpinStartBannerRankStyles);
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_RANK_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_RANK_POS.y;
    return autoResizeText;
  }

  private initDescription(): AutoResizeText {
    const autoResizeText = new AutoResizeText(
      i18n.t('freeSpinStartBanner.description'),
      freeSpinStartBannerDescriptionStyles,
    );
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_DESCRIPTION_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_DESCRIPTION_POS.y;
    return autoResizeText;
  }

  private initMultiplier(): AutoResizeText {
    const fishConfig = fishIconConfig[this.fishKind];
    const autoResizeText = new AutoResizeText(`x${fishToMultiplier[this.fishKind]}`, fishConfig.textStyle);
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = fishConfig.pos.x;
    autoResizeText.y = fishConfig.pos.y;
    return autoResizeText;
  }

  public setFishKind(fishKind: FishKind) {
    this.removeChild(this.multiplier, this.rank);

    this.fishKind = fishKind;
    this.base.state.setAnimation(1, fishToBaseAnim[this.fishKind], true);
    this.multiplier = this.initMultiplier();
    this.rank = this.initRank();
    this.addChild(this.rank, this.multiplier);
  }
}
