import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import ViewContainer from '../components/container';
import { layerAnnounce } from '../components/layers/layers';
import { TickerSpine } from '../components/spine/index';
import { EventTypes, eventManager } from '../config';
import { Icon } from '../d';

import { AnnounceType, announcePositions } from './config';

export class AnnounceContainer extends ViewContainer {
  private anticipation: TickerSpine<'longspin'>;

  //private spine: TickerSpine<'anti'>;
  constructor(_spinResult: Icon[]) {
    super();

    this.anticipation = new TickerSpine('longspin');
    this.anticipation.visible = false;
    this.anticipation.position.copyFrom(announcePositions.anticipation);

    // this.spine = new TickerSpine<'anti'>('anti');
    // this.spine.update(0);
    // this.spine.state.setEmptyAnimation(0);
    // this.spine.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    // this.addChild(this.spine);
    // this.visible = false;

    this.addChild(this.anticipation);
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.START_ANNOUNCEMENT, this.startAnnounceAnimation.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.reelStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {});
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.startAnticipationAnimation.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.endAnticipationAnimation.bind(this));

    this.parentLayer = layerAnnounce;
  }

  private startAnticipationAnimation(durationType: number) {
    this.anticipation.state.setAnimation(0, 'loop', true);
    this.anticipation.visible = true;
    this.visible = true;

    const AudioDurationToString = ['short', 'middle', 'long'];
    const AudioSlotType = 'SC';

    const audioName = `XT005S_${AudioSlotType}_longspin_${AudioDurationToString[durationType]}`;
    AudioApi.play({ type: audioName, stopPrev: true });
  }
  private endAnticipationAnimation() {
    this.anticipation.state.setEmptyAnimation(0);
    this.anticipation.visible = false;

    AudioApi.stop({ type: ISongs.XT005S_SC_longspin_short, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT005S_SC_longspin_middle, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT005S_SC_longspin_long, stopPrev: true });
  }

  private startAnnounceAnimation(announceType: AnnounceType) {
    switch (announceType) {
      default:
      case 'None':
      case 'phoenix':
        break;
      case 'low': {
        //        this.spine.state.setAnimation(0, '01', false);
        //        this.spine.state.addEmptyAnimation(0);
        //        this.visible = true;

        //const delay = Tween.createDelayAnimation(this.spine.getAnimation(0, '01').duration * 1000);
        //delay.addOnComplete(() => {
        //   this.visible = false;
        // });
        // delay.start();
        AudioApi.play({ type: ISongs.XT005S_anticipation_low, stopPrev: true });

        break;
      }
      case 'high': {
        // this.spine.state.setAnimation(0, '02', false);
        // this.spine.state.addEmptyAnimation(0);
        // this.visible = true;

        // const delay = Tween.createDelayAnimation(this.spine.getAnimation(0, '02').duration * 1000);
        // delay.addOnComplete(() => {
        //   this.visible = false;
        // });
        // delay.start();
        AudioApi.play({ type: ISongs.XT005S_anticipation_high, stopPrev: true });

        break;
      }
    }
  }

  private reelStopped(): void {
    this.visible = false;
  }

  private hideContainer(): void {
    this.visible = false;
  }
}
