import ViewContainer from '../components/container';
import { layerReelFrameFront } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

class FishOn extends ViewContainer {
  private spine: TickerSpine<'symbol_sc'>;

  constructor() {
    super();
    this.spine = new TickerSpine('symbol_sc');
    this.spine.state.setEmptyAnimation(0);

    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.start.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, () => {
      this.spine.state.setEmptyAnimation(0);
      this.visible = false;
    });
    eventManager.on(EventTypes.RESIZE, this.handleResize.bind(this));

    this.addChild(this.spine);

    this.visible = false;
    this.parentLayer = layerReelFrameFront;
  }

  private start() {
    this.spine.state.setAnimation(0, 'win', true);
    this.visible = true;
    //AudioApi.play({ type: ISongs.XT005S_challenge_roulette, stopPrev: true });
  }

  public handleResize(width: number, height: number): void {
    if (height > width) {
      this.spine.x = 390;
      this.spine.y = 390;
    } else {
      this.spine.x = 390;
      this.spine.y = 390;
    }
  }
}
export default FishOn;
