import { FishKind } from '../../config';

export type AnnounceType = 'None' | 'low' | 'high' | 'phoenix';

export type AnnounceLotsType = 'SC3' | 'SC4';

export const DummyFishKindLots: number[] = [1, 4, 11, 171, 333, 329, 151];
export const DummyFishNoToFishKind: FishKind[] = ['Rank1', 'Rank2', 'Rank3', 'Rank4', 'Rank5', 'Rank6', 'Rank7'];
export const FishRankToLotsTbl: Record<FishKind, Record<AnnounceLotsType, number[]>> = {
  Rank1: {
    SC3: [850, 80, 70],
    SC4: [350, 325, 325],
  },
  Rank2: {
    SC3: [850, 80, 70],
    SC4: [350, 325, 325],
  },
  Rank3: {
    SC3: [850, 80, 70],
    SC4: [350, 325, 325],
  },
  Rank4: {
    SC3: [850, 80, 70],
    SC4: [350, 325, 325],
  },
  Rank5: {
    SC3: [850, 80, 70],
    SC4: [350, 325, 325],
  },
  Rank6: {
    SC3: [850, 150, 0],
    SC4: [500, 500, 0],
  },
  Rank7: {
    SC3: [850, 150, 0],
    //SC3: [0, 1000, 0],
    SC4: [500, 500, 0],
  },
};
export const AnnounceReelRollingExternalDuration: Record<AnnounceType, number> = {
  None: 0,
  low: 3446,
  high: 3888,
  phoenix: 4000,
};

export const announcePositions = {
  anticipation: { x: 400, y: 400 },
};

export const ANNOUNCE_REEL_ALPHA = 0.1;

//export const LotsFishingPhoenix: number[] = [75, 25];
export const LotsFishingPhoenix: number[] = [0, 100];
