import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { FishKind, ISongs, SlotId } from '../config';
import { GameMode } from '../consts';
import {
  AnticipationInfo,
  AnticipationReelType,
  AnticipationType,
  MessageFreeSpinEndBannerProps,
  ReelStopSoundType,
} from '../global';
import { getFragmentData } from '../gql/__generated__/fragment-masking';
import {
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setDefaultReelSetId,
  setFishingResult,
  setForceReelStop,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAllowForceReelStop,
  setIsAutoSpins,
  setIsBuyFeaturePurchased,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsSpinShortCut,
  setIsStopOnFeatureWin,
  setIsSuperBuyFeature,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastBaseReelPositions, //setLastRegularReelPositions,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelSet,
  setPrevReelsPosition,
  setReelSetType,
  setResumeDuringFreeSpin,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { outcomeFragment, wagerFragment } from '../gql/fragment';
import { isStoppedGql } from '../gql/query';
import { IBonusData, ISettledBet } from '../types';
import {
  //checkNonBonusAutoSpinStop,
  //getAnimationDurationFromSpine,
  getBgTypeForGameMode,
  getBonusFromRewards,
  getBonusOutcomePredicament,
  getNonNullableValue,
  getOutcomePredicament,
  getReelPositions,
  getSpinResult1x4,
  getWagerSettings,
  hasWin,
  isBaseGameMode, //  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFishingChallengeMode,
  isFreeSpinsMode,
  normalizeCoins,
  showCurrency,
} from '../utils';
import { getBetData } from '../utils/fragments';

import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import { AnnounceContainer } from './announce/announceContainer';
import {
  AnnounceLotsType,
  AnnounceType,
  DummyFishKindLots,
  DummyFishNoToFishKind,
  FishRankToLotsTbl,
} from './announce/config';
import { getRandomFromUUID, getResultFromTbl } from './announce/utils';
import Backdrop from './backdrop/backdrop';
import Background, { BgType } from './background/background';
import bgmControl, { BgmControl } from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { BuyFeaturePopup } from './buyFeature/buyFeaturePopup';
import { BuyFeaturePopupConfirm } from './buyFeature/buyFeaturePopupConfirm';
import {
  EventTypes,
  FREESPIN_ENDING_DELAY_DURATION,
  FREE_SPINS_ROUND_INTERVAL,
  FREE_SPINS_ROUND_INTERVAL_TURBO,
  FREE_SPINS_TIME_OUT_BANNER,
  FREE_SPIN_START_REEL_POSITIONS,
  JINGLE_TO_WIN_DURATION,
  REELS_AMOUNT, //SLOTS_PER_REEL_AMOUNT,
  SLOT_REELMASK_HEIGHT,
  SLOT_REELMASK_WIDTH,
  SLOT_REELMASK_X,
  SLOT_REELMASK_Y,
  SlotMachineState,
  WIN_ANIM_START_DELAY,
  WinStages,
  eventManager,
} from './config';
import { createUIButtonContainer } from './controlButtons';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import FishingGambleAllScreen from './fishing/fishingGambleAllScreen';
import FishPrizeBg from './fishing/fishingPrizeBg';
import GameView from './gameView/gameView';
import LinesContainer from './lines/lineContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import ReelTintContainer from './tint/reelTintContainer';
import { SlotsAnimationContainer } from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: RecursiveNonNullable<ISlotConfig>;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public spinResult: Icon[];

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotConfig as RecursiveNonNullable<ISlotConfig>,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainers: ReelsContainer[];

  public reelTintContainer: ReelTintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotAnimationContainer: SlotsAnimationContainer;

  public announceContainer: AnnounceContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public fishingPrizeBg: FishPrizeBg;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public buyFeatureBtn?: BuyFeatureBtn;

  public buyFeaturePopup?: BuyFeaturePopup;

  public buyFeaturePopupConfirm?: BuyFeaturePopupConfirm;

  private phoenix: Phoenix;

  public linesContainer: LinesContainer;

  private announceType: AnnounceType = 'None';

  private anticipationInfo: AnticipationInfo = { type: 'None', reels: [] };

  private slotMachineContainer: PIXI.Container;

  private backDrop?: Backdrop;

  private fishingGambleAllScreen: FishingGambleAllScreen;

  private constructor(
    application: PIXI.Application,
    slotConfig: RecursiveNonNullable<ISlotConfig>,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const lastResult = setUserLastBetResult();
    const outComes = lastResult && getNonNullableValue(getFragmentData(outcomeFragment, lastResult?.outcomes));
    const predicament = outComes && getNonNullableValue(outComes[0]?.predicament);

    const startPosition =
      predicament && 'reelPositions' in predicament
        ? predicament.reelPositions
        : slotConfig.slotSettings.startingPositions;
    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    //TO DO
    const reelSetId = lastResult ? this.slotConfig.reels[0]?.type : setDefaultReelSetId(); //TO DO
    const reelSet = { ...this.slotConfig.reels.find((reelSet) => reelSet.type === reelSetId)! };
    setReelSetType(reelSet.type); //TO DO

    //    let { startingPositions } = slotConfig.slotSettings;
    /*
    if (setBrokenBuyFeatureGame()) {
      reelSet = setUserLastBetResult()?.id
        ? slotConfig.reels.find((reelSet) => {
            return reelSet.type === GameMode.BUY_FEATURE;
          })!
        : slotConfig.reels[0];
        setReelSetType(reelSet.type);
      //this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    } else {
      /*const hasLastBet = setUserLastBetResult()?.id;
            startingPositions = hasLastBet
        ? setUserLastBetResult().outcomes[0].predicament.reelPositions
        : startingPositions;
      if (setCurrentBonus().state === BonusStatus.OPEN && setCurrentBonus().roundsPlayed === 0) {
        startingPositions = [0, 0, 0, 0, 0]
      }
    }
      */
    this.reelsContainers = [];
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reelsContainers.push(new ReelsContainer(reelSet.layout, startPosition, i));
    }
    this.reelTintContainer = new ReelTintContainer();
    const spinResult = getSpinResult1x4({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });
    setPrevReelSet(reelSet);
    this.spinResult = spinResult;
    this.slotAnimationContainer = new SlotsAnimationContainer();
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.announceContainer = new AnnounceContainer(spinResult);
    this.background = new Background();
    this.fishingPrizeBg = new FishPrizeBg();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);
    this.linesContainer = new LinesContainer(slotConfig.lines);

    this.gameView = new GameView({
      announceContainer: this.announceContainer,
      slotStopDisplayContainer: this.slotAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainers: this.reelsContainers,
      miniPayTableContainer: this.miniPayTableContainer,
      reelTintContainer: this.reelTintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      linesContainer: this.linesContainer,
    });

    //    if (isBuyFeatureEnabled(slotConfig.slotSettings.buyFeaturePackages)) {
    this.buyFeatureBtn = new BuyFeatureBtn();
    this.buyFeaturePopup = new BuyFeaturePopup();
    this.buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    this.backDrop = new Backdrop(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    this.gameView.addChild(this.buyFeatureBtn, this.backDrop, this.buyFeaturePopup, this.buyFeaturePopupConfirm);
    //    }
    const uiButtonContainer = createUIButtonContainer();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.safeArea.addChild(this.gameView);
    this.phoenix = new Phoenix();

    this.fishingGambleAllScreen = new FishingGambleAllScreen();

    this.slotMachineContainer = new PIXI.Container();
    this.slotMachineContainer.addChild(this.background);
    this.slotMachineContainer.addChild(this.safeArea);
    this.slotMachineContainer.addChild(this.bottom);
    this.slotMachineContainer.addChild(uiButtonContainer);
    this.slotMachineContainer.addChild(this.phoenix);
    this.fadeArea = new FadeArea();
    this.slotMachineContainer.addChild(this.fadeArea);
    this.slotMachineContainer.addChild(this.fishingPrizeBg);
    this.slotMachineContainer.addChild(this.fishingGambleAllScreen);

    this.application.stage.addChild(this.slotMachineContainer);

    this.gameView.interactive = true;
    this.gameView.hitArea = new PIXI.Rectangle(
      SLOT_REELMASK_X,
      SLOT_REELMASK_Y,
      SLOT_REELMASK_WIDTH,
      SLOT_REELMASK_HEIGHT,
    );

    this.gameView.on('mousedown', () => {
      this.skipWinAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipWinAnimations();
    });

    if (setBrokenBuyFeatureGame() !== '') {
      const initWait = Tween.createDelayAnimation(1000);
      initWait.addOnComplete(() => {
        eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, setBrokenBuyFeatureGame());
      });
      initWait.start();
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    } else if (setBrokenGame()) {
      this.state = SlotMachineState.WINNING;
      this.onBrokenGame();
      setResumeDuringFreeSpin(true);
    }
  }

  private onBrokenGame(): void {
    const lastResult = setUserLastBetResult()!;
    const gamemode = setCurrentBonus().gameMode;
    const outcome = getFragmentData(outcomeFragment, lastResult.outcomes!);
    const reelPositions =
      outcome[0]?.predicament && 'reelPositions' in outcome[0].predicament
        ? outcome[0]?.predicament.reelPositions
        : [0, 0, 0, 0];

    const bgType: BgType = getBgTypeForGameMode(gamemode);

    const settings = {
      mode: gamemode,
      reelPositions: getNonNullableValue(reelPositions),
      reelSetId: gamemode,
      bgType: bgType,
    };
    eventManager.emit(EventTypes.CHANGE_MODE, settings);

    switch (gamemode) {
      case GameMode.BASE_GAME:
        /*        if (lastResult.userBonus?.bonusId === BonusKind.FREE_SPINS) {
          this.updateRegularWin(lastResult.effectiveCoinAmount); // TO DO
        }
          */
        break;
      case GameMode.FREE_SPINS:
        /*
        setIsFreeSpinsWin(true);
        bgmControl.setRestrict(true);
        eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
          mode: setGameMode(),
          bgType: getBgTypeForGameMode(setGameMode()),
        });
        bgmControl.setRestrict(false);
        */
        break;

      case GameMode.BUY_FEATURE:
        break;
      default:
        break;
    }

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(EventTypes.SET_RESPIN_VIEW);
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.END_FREESPINS, this.endFreeSpins.bind(this));

    eventManager.addListener(EventTypes.SET_IS_ALLOW_FORCE_REEL_STOP, this.checkForceReelStop.bind(this));
    eventManager.addListener(EventTypes.SCALING_STOP, this.scalingStop.bind(this));
    eventManager.addListener(EventTypes.FISHING_START_BANNER, this.startFreeSpinBanner.bind(this));
  }

  public throwTimeoutError(): void {
    if (setIsAutoSpins()) setIsAutoSpins(false);
    setIsAllowForceReelStop(false);
    setIsSpinShortCut(false);
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    AudioApi.stop({ type: ISongs.XT005S_spin_loop });
    this.setState(SlotMachineState.IDLE);
    setIsSpinShortCut(false);
    setForceReelStop(false);
    setIsAllowForceReelStop(false);

    this.isSpinInProgressCallback();

    const spinResult = getSpinResult1x4({
      reelPositions: setPrevReelsPosition(),
      reelSet: setPrevReelSet(),
      icons: this.slotConfig.icons,
    });
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
  }

  private changeReelSetOnModechange(reelSetId: string, reelPositions: number[]) {
    //change reelset & display reel
    setReelSetType(reelSetId);
    const reels = getNonNullableValue(setSlotConfig().reels);
    const reelSet = reels.find((reels) => reels.type === reelSetId);
    const spinResult = getSpinResult1x4({
      reelPositions: reelPositions.slice(0, REELS_AMOUNT),
      reelSet: reelSet!,
      icons: getNonNullableValue(setSlotConfig().icons),
    });
    eventManager.emit(EventTypes.CHANGE_REEL_SET, {
      reelSet: reelSet!,
      reelPositions: reelPositions,
    });
    this.miniPayTableContainer.setSpinResult(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    setPrevReelSet(reelSet);
    setPrevReelsPosition(reelPositions);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
    }
    if (!(currentGameMode === GameMode.BASE_GAME)) {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION, settings.mode === GameMode.BASE_GAME);
    }

    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.isSlotBusyCallback();
        this.isSpinInProgressCallback();
        setIsSpinShortCut(false);

        setIsFreeSpinsWin(false);
        setCurrentBonus({
          ...setCurrentBonus(),
        });
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, setNextResult()!.balance.settled);
        if (previousGameMode != GameMode.BASE_GAME) {
          //change reelset & display reel
          //          this.changeReelSetOnModechange(settings.reelSetId, settings.reelPositions);
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({
              currency: setCurrency(),
              value: normalizeCoins(setCurrentFreeSpinsTotalWin()),
              showCurrency: showCurrency(setCurrency()),
            }),
          );
        }
        this.setState(SlotMachineState.IDLE);
        break;

      case GameMode.FISH_CHALLENGE:
        eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);

        const bonus = setCurrentBonus();
        if (!bonus) throw new Error('Something went wrong');

        if (!setBrokenGame()) {
          setCurrentBonus({
            ...bonus,
          });
        }

        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

        if (previousGameMode === GameMode.BASE_GAME) {
          BgmControl.playBgm('roulette', false, true);
          //const fishRank = getBonusOutcomePredicament(setNextResult()!)?.fishKind ?? 'Rank1';
          //eventManager.emit(EventTypes.FISHING_START_ROULETTE, fishRank);
        } else {
          this.isSlotBusyCallback();
          this.setState(SlotMachineState.IDLE);
        }
        break;
      case GameMode.BUY_FEATURE:
      case GameMode.BUY_FEATURE_SP:
        break;
      case GameMode.FISH_GAMBLE: {
        this.setState(SlotMachineState.IDLE);

        break;
      }
      case GameMode.SCALING: {
        this.setState(SlotMachineState.IDLE);
        break;
      }
      case GameMode.FISH_GAMBLE_SELECT:
        this.setState(SlotMachineState.IDLE);

        break;
      case GameMode.FREE_SPINS:
        break;
      default:
        break;
    }
  }

  private startFreeSpinBanner() {
    const callback = () => {
      eventManager.emit(EventTypes.FISHING_CHANGE_WHEEL, 0);
      this.setState(SlotMachineState.IDLE);
    };

    const fishRank = getBonusOutcomePredicament(setNextResult()!)?.fishKind ?? 'Rank1';

    const isAutoSkipTitle = setIsContinueAutoSpinsAfterFeature() && !setIsStopOnFeatureWin() ? true : false;

    if (isAutoSkipTitle) {
      // auto continue
      const titleDispWait = Tween.createDelayAnimation(setBrokenGame() ? 3000 : 3000);
      titleDispWait.addOnComplete(() => {
        eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      });

      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        callback: callback,
        preventDefaultDestroy: false,
        onInitCallback: () => {
          titleDispWait.start();
        },
        fishRank: fishRank,
        isSuperBuyFeature: setIsSuperBuyFeature(),
      });
    } else {
      this.isSlotBusyCallback();

      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        callback: callback,
        preventDefaultDestroy: false,
        fishRank: fishRank,
        isSuperBuyFeature: setIsSuperBuyFeature(),
      });
    }
  }

  private startFreeSpins(): void {
    const predicament = getBonusOutcomePredicament(setNextResult()!);

    setLastBaseReelPositions(getReelPositions(this.nextResult!));

    BgmControl.setRestrict(true);
    BgmControl.fadeOutAll(500);

    const modeChangeCallback = () => {
      setIsFreeSpinsWin(true);
      BgmControl.setRestrict(false);

      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.FISH_CHALLENGE,
        reelPositions: FREE_SPIN_START_REEL_POSITIONS,
        reelSetId: setCurrentBonus().gameMode!,
        bgType: 'roulette',
        fishKind: predicament?.fishKind!,
      });
      eventManager.emit(EventTypes.FISHING_START_ROULETTE, predicament?.fishKind!);
    };
    eventManager.emit(EventTypes.TRANSITION_START, {
      type: 'Fade',
      fadeOutDuration: 1000,
      fadeInDuration: 1000,
      tint: 0xffffff,
      callback: modeChangeCallback,
    });
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, true);
  }

  private hideWinCount() {
    this.skipWinAnimations();

    eventManager.emit(EventTypes.SET_WIN_VISIBILITY, WinStages.None);
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private async endFreeSpins(): Promise<void> {
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      const modeChangeCallback = () => {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
        eventManager.emit(EventTypes.CHANGE_MODE, {
          mode: GameMode.BASE_GAME,
          reelSetId: setDefaultReelSetId(),
          reelPositions: setLastBaseReelPositions(),
          bgType: 'default',
        });
      };
      eventManager.emit(EventTypes.TRANSITION_START, {
        type: 'Fade',
        fadeOutDuration: 1000,
        fadeInDuration: 1000,
        tint: 0xffffff,
        callback: modeChangeCallback,
      });
    };
    this.hideWinCount();

    const freeSpinEndMessageBannerOptions: MessageFreeSpinEndBannerProps = {
      totalWin: `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
      totalWinAmount: setFreeSpinsTotalWin(),
      totalRoundPlayed: setCurrentBonus().roundsPlayed,
      preventDefaultDestroy: true,
      fishKind: getOutcomePredicament(setNextResult()!)?.fishKind ?? 'Rank1',
    };

    if (!setIsContinueAutoSpinsAfterFeature()) {
      freeSpinEndMessageBannerOptions.callback = callback;
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      freeSpinEndMessageBannerOptions.onInitCallback = () => delay.start();
    }
    eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, freeSpinEndMessageBannerOptions);
    this.skipWinAnimations();
    setBrokenGame(false);
    this.isSlotBusyCallback();
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(reelIdx = REELS_AMOUNT - 1): void {
    this.reelsContainers[reelIdx]!.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    if (isFishingChallengeMode(setGameMode())) {
      // check
      if (this.state === SlotMachineState.IDLE) {
        setIsAllowForceReelStop(false);
        setIsSpinShortCut(false);
        eventManager.emit(EventTypes.FISHING_WHEEL_SPIN_WAIT);
        this.isStopped = false;
        this.isReadyForStop = false;
        //this.nextResult = null;
        this.setState(SlotMachineState.SPIN);
      } else if (this.state === SlotMachineState.SPIN) {
        this.isStopped = true;
        setIsSpinShortCut(true);
        if (setIsAllowForceReelStop()) {
          //turbo wheel
          eventManager.emit(EventTypes.SET_FISHING_CHALLENGE_TURBO_SPIN);
        }
      }
      return;
    } else if (setGameMode() === GameMode.SCALING) {
      return;
    } else if (setGameMode() === GameMode.FISH_GAMBLE) {
      return;
    }

    this.reelsContainers.forEach((reelContainer) => {
      reelContainer.forcedStop = false;
    });

    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      setIsSpinShortCut(true);
      if (this.nextResult) {
        if (!this.isReadyForStop && setIsAllowForceReelStop()) {
          this.isReadyForStop = true;
          this.removeErrorHandler(REELS_AMOUNT - 1);
          //this.dynamicReelSetChange();
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            getReelPositions(this.nextResult),
            this.anticipationInfo,
            this.announceType,
            this.spinResult,
          );
        }
        if (setIsAllowForceReelStop()) {
          this.stopSpin();
        }
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.skipWinAnimations();
      eventManager.emit(EventTypes.START_SPIN_ANIMATION, isTurboSpin); // change order skipAnimation
      //eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.announceType = 'None';
      this.anticipationInfo = { type: 'None', reels: [] };
      setIsSpinShortCut(false); //dont change order
      setIsAllowForceReelStop(true); //dont change order
      setForceReelStop(false);

      this.setState(SlotMachineState.SPIN);

      let spinAnimation: AnimationGroup;
      if (isBuyFeatureMode()) {
        spinAnimation = this.getSynchroSpinAnimation(!!isTurboSpin);
      } else {
        spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipWinAnimations();
    }
  }

  public scalingSpin(): void {
    if (this.state === SlotMachineState.IDLE) {
      setIsAllowForceReelStop(false);
      setIsSpinShortCut(false);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.setState(SlotMachineState.SPIN);
    } else {
      this.isStopped = true;
      setIsSpinShortCut(true);
      if (SlotMachineState.SPIN === this.state) {
        eventManager.emit(EventTypes.SCALING_SKIP);
      }
    }
  }

  private scalingStop(): void {
    this.setState(SlotMachineState.STOP);
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainers[i]!.reels[0]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            //this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getReelPositions(this.nextResult),
              this.anticipationInfo,
              this.announceType,
              this.spinResult,
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainers[i]!.reels[0]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);

            const stopAnimDuration =
              PIXI.Loader.shared.resources['symbol_sc1']?.spineData?.findAnimation('stop').duration;
            const stopDelayAnimation = Tween.createDelayAnimation((stopAnimDuration ?? 0) * 1000);
            stopDelayAnimation.addOnComplete(() => {
              eventManager.emit(EventTypes.REELS_STOP_ANIMATION_COMPLETE, isTurboSpin);
            });
            stopDelayAnimation.start();
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getSynchroSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainers[i]!.reels[0]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin, true);

      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            //this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getReelPositions(this.nextResult),
              this.anticipationInfo,
              'None',
              this.spinResult,
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainers[i]!.reels[0]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);

            const stopAnimDuration =
              PIXI.Loader.shared.resources['symbol_sc1']?.spineData?.findAnimation('stop').duration;
            const stopDelayAnimation = Tween.createDelayAnimation((stopAnimDuration ?? 0) * 1000);
            stopDelayAnimation.addOnComplete(() => {
              eventManager.emit(EventTypes.REELS_STOP_ANIMATION_COMPLETE, isTurboSpin);
            });
            stopDelayAnimation.start();
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getFreeSpinBonus(): IBonusData | undefined | null {
    const wager = getFragmentData(wagerFragment, getBetData(this.nextResult!).wager)!;
    //const outcome = getFragmentData(outcomeFragment, getBetData(this.nextResult!).outcomes)![0];
    const outcomes = getFragmentData(outcomeFragment, getBetData(this.nextResult!).outcomes);

    const bonusOutcome = outcomes?.find((outcome) => {
      return outcome.rewards?.find((reward) => {
        return reward.entityId === 'FREE_SPINS';
      });
    });

    //TO DO
    if (bonusOutcome != undefined) {
      return getBonusFromRewards(wager, bonusOutcome);
    }

    return undefined;
  }

  // TO DO
  private getSpecialRound(): IBonusData | undefined | null {
    const wager = getFragmentData(wagerFragment, getBetData(this.nextResult!).wager)!;
    const outcome = getFragmentData(outcomeFragment, getBetData(this.nextResult!).outcomes)![0];

    if (outcome!.stateSnapshot?.hasRewardedFreeSpins) {
      return getBonusFromRewards(wager, outcome!);
    }

    return undefined;
  }
  private updateRegularWin(winAmount: number) {
    setWinAmount(winAmount);
    setLastRegularWinAmount(winAmount);
  }
  private updateTotalWin(winAmount: number) {
    if (winAmount != 0) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmount);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    }
  }

  private checkBonusAndNextSpin() {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    const specialRoundBonus = this.getSpecialRound();
    const winAmount = this.nextResult?.winCoinAmount!;

    if (isBaseGameMode(mode)) {
      this.updateRegularWin(winAmount);
    } else if (isFreeSpinsMode(mode)) {
      this.updateTotalWin(winAmount);
    }

    if (isFishingChallengeMode(setGameMode())) {
      this.setState(SlotMachineState.IDLE);
    } else {
      if (freeSpinsBonus) {
        //const bonusKind = getBonusKind(freeSpinsBonus?.packageId); // TO DO

        setCurrentBonus({
          ...freeSpinsBonus,
        });
        if (mode === GameMode.BASE_GAME) {
          setCurrentFreeSpinsTotalWin(winAmount);

          const startFreeSpinDelay = Tween.createDelayAnimation(500);
          startFreeSpinDelay.addOnComplete(() => {
            this.startFreeSpins();
          });
          startFreeSpinDelay.start();
        } else if (mode === GameMode.FREE_SPINS) {
          this.setState(SlotMachineState.IDLE);
        } else {
          //error
        }

        /*
      switch (bonusKind) {
        case BonusKind.FREE_SPINS: {
          if (mode === GameMode.BASE_GAME) {
            setCurrentFreeSpinsTotalWin(winAmount);

            const startFreeSpinDelay = Tween.createDelayAnimation(500);
            startFreeSpinDelay.addOnComplete(() => {
              this.startFreeSpins();
            });
            startFreeSpinDelay.start();
          } else if (mode === GameMode.FREE_SPINS) {
            this.setState(SlotMachineState.IDLE);
          } else {
            //error
          }
          break;
        }
      }
        */
      } else if (specialRoundBonus) {
        // no reach
      } else {
        switch (mode) {
          case GameMode.BASE_GAME: {
            eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
            this.setState(SlotMachineState.IDLE);
            break;
          }
          case GameMode.FREE_SPINS: {
            //this.waitEndFreeSpins();
            //} else {
            this.setState(SlotMachineState.IDLE);
            //}
            break;
          }
          case GameMode.BUY_FEATURE:
          default:
            //error
            break;
        }
      }
    }
  }

  private waitEndFreeSpins() {
    const freespinsEndingDelay = Tween.createDelayAnimation(FREESPIN_ENDING_DELAY_DURATION);
    freespinsEndingDelay.addOnComplete(() => {
      setCurrentBonus({ ...setCurrentBonus() });
      eventManager.emit(EventTypes.END_FREESPINS);
    });
    freespinsEndingDelay.start();
  }

  public isTriggerFs(): boolean {
    /*
    return (
      (!setCurrentBonus().isActive ||
        (setCurrentBonus().isActive &&
          (setCurrentBonus().gameMode === GameMode.BUY_FEATURE ||
            (setCurrentBonus().gameMode === GameMode.FREE_SPIN && setCurrentBonus().roundsPlayed === 0)))) &&
      this.nextResult != null &&
      this.nextResult!.bet.outcomes[0]!.stateSnapshot.fieldState[4] === SlotId.WL &&
      (this.nextResult!.bet.wager.wagerStorage.roundsPlayed === null ||
        this.nextResult!.bet.wager.wagerStorage.roundsPlayed === 0) &&
      !setIsReplay()
    );
    */
    return false;
  }

  private onCountUpEnd(): void {
    this.isSpinInProgressCallback();

    const animationChain = new AnimationChain();
    animationChain.appendAnimation(Tween.createDelayAnimation(100));

    if (isFishingChallengeMode(setGameMode())) {
      this.checkBonusAndNextSpin();
    } else {
      animationChain.addOnComplete(() => {
        this.checkBonusAndNextSpin();
      });
    }
    animationChain.start();
  }

  private dynamicReelSetChange(): void {
    if (setReelSetType() !== setGameMode()) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: this.slotConfig.reels.find((reels) => reels.type === setGameMode())!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetType(setGameMode());
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    setIsBuyFeatureSpin(false);
    setIsBuyFeaturePurchased(false);
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationInfo(spinResult: Icon[]): AnticipationInfo {
    const requiredIconsBonusG: SlotId[] = [SlotId.SC1, SlotId.SC2, SlotId.SC3];
    const requiredIconsBonus: SlotId[] = [SlotId.SC1, SlotId.SC2, SlotId.SC3, SlotId.SC4];

    const checkIcons = (requiredIcons: SlotId[]) =>
      requiredIcons.every((icon) => spinResult.some((spin) => spin.id === icon));

    const isBonus = checkIcons(requiredIconsBonus);
    const isBonusGase = checkIcons(requiredIconsBonusG);

    const anticipationType: AnticipationType = isBonus ? 'Bonus' : isBonusGase ? 'BonusG' : 'None';

    const createReel = (type: AnticipationType, soundType: ReelStopSoundType): AnticipationReelType => ({
      type,
      soundType,
    });

    const reelStopSounds = this.getReelStopSoundType(spinResult);

    return {
      type: anticipationType,
      reels: [
        createReel('None', reelStopSounds[0]!),
        createReel('None', reelStopSounds[1]!),
        createReel('None', reelStopSounds[2]!),
        createReel(anticipationType, reelStopSounds[3]!),
      ],
    };
  }

  private getAnnounceType(_nextResult: ISettledBet): AnnounceType {
    if (isBuyFeatureMode() || isFreeSpinsMode(setGameMode())) return 'None';

    if (this.anticipationInfo.type === 'None') return 'None';

    //const freeSpinsBonus = this.getFreeSpinBonus();
    const lotResultToAnnounceType: AnnounceType[] = ['None', 'low', 'high'];
    const predicament = getBonusOutcomePredicament(setNextResult()!);

    const announceType: AnnounceLotsType =
      this.anticipationInfo.type === 'Bonus' ? 'SC4' : this.anticipationInfo.type === 'BonusG' ? 'SC3' : 'SC3';

    const dummyFishNo = getResultFromTbl(DummyFishKindLots, getRandomFromUUID(getBetData(this.nextResult!).id, 1000));
    const dummyFishKind: FishKind = DummyFishNoToFishKind[dummyFishNo] ?? 'Rank7';

    const fishKind = predicament?.fishKind ?? dummyFishKind;

    const result = getResultFromTbl(
      FishRankToLotsTbl[fishKind][announceType],
      getRandomFromUUID(getBetData(this.nextResult!).id, 1000),
    );

    return lotResultToAnnounceType[result] ?? 'None';

    //const isBonus = freeSpinsBonus ? getBonusKind(freeSpinsBonus.bonus!.id) : undefined;
    //const isBonus = freeSpinsBonus ? BonusKind.FREE_SPINS : undefined; // TO DO
    //const isFreeSpinBonus = isBonus === BonusKind.FREE_SPINS ? true : false;

    //const info = AnnounceDataTbl.find((info) => info.bonus === isFreeSpinBonus && info.snowCount.includes(snowCount));

    //return 'None';
    /*    return info && info.lotTbl.length > 0
      ? AnnounceConvTbl[
          getResultFromTbl(info.lotTbl, getRandomFromUUID(getFragmentData(betFragment, this.nextResult!.bet).id, 1000))
        ]!
      : 'None';
      */
  }

  private getReelStopSoundType(spinResult: Icon[]): ReelStopSoundType[] {
    const requiredIconsBonus1: SlotId[] = [SlotId.SC1];
    const requiredIconsBonus2: SlotId[] = [SlotId.SC1, SlotId.SC2];
    const requiredIconsBonus3: SlotId[] = [SlotId.SC1, SlotId.SC2, SlotId.SC3];
    const requiredIconsBonus4: SlotId[] = [SlotId.SC1, SlotId.SC2, SlotId.SC3, SlotId.SC4];

    const checkIcons = (requiredIcons: SlotId[]) =>
      requiredIcons.every((icon) => spinResult.some((spin) => spin.id === icon));

    return [
      checkIcons(requiredIconsBonus1) ? 'bonus' : 'normal',
      checkIcons(requiredIconsBonus2) ? 'bonus' : 'normal',
      checkIcons(requiredIconsBonus3) ? 'bonus' : 'normal',
      checkIcons(requiredIconsBonus4) ? 'bonus' : 'normal',
    ];
  }
  private skipWinAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    //const betData = getBetData(result);
    const reelSet = setSlotConfig().reels.find((reel) => reel.type === getWagerSettings(result)!.gameMode);
    let spinResult = [];
    const reelPositions = getReelPositions(result);

    switch (setGameMode()) {
      case GameMode.BASE_GAME:
        break;
      case GameMode.BUY_FEATURE:
        break;
      case GameMode.BUY_FEATURE_SP:
        break;
      case GameMode.FISH_CHALLENGE: {
        const predicament = getOutcomePredicament(result);
        setFishingResult(predicament);
        setIsAllowForceReelStop(true);
        eventManager.emit(EventTypes.FISHING_WHEEL_SPIN_START);
        //setIsSpinInProgress();
        //setIsSlotBusy();
        return;
      }
      case GameMode.FISH_GAMBLE_SELECT:
        break;
      case GameMode.FISH_GAMBLE:
        const gamblePredicament = getOutcomePredicament(result);
        setFishingResult(gamblePredicament);
        eventManager.emit(
          EventTypes.FISHING_GAMBLE_START_WAIT,
          setFishingResult()?.fishKind!,
          setFishingResult()?.fishingGamblePosition!,
          setFishingResult()?.fishingGambleResult!,
        );
        //setIsAllowForceReelStop(true);
        //setIsSpinInProgress();
        return;
      case GameMode.SCALING:
        const scalingPredicament = getOutcomePredicament(result);
        setFishingResult(scalingPredicament);
        eventManager.emit(
          EventTypes.FISHING_SCALING_START,
          setFishingResult()?.fishKind!,
          setFishingResult()?.fishingScalingMultiplier!,
        );
        setIsAllowForceReelStop(true);
        //setIsSpinInProgress();
        return;
      case GameMode.FREE_SPINS:
        break;
    }
    spinResult = getSpinResult1x4({
      reelPositions,
      reelSet: reelSet!,
      icons: this.slotConfig.icons,
    });

    this.spinResult = spinResult;
    setPrevReelsPosition(reelPositions);
    setPrevReelSet(reelSet);
    this.nextResult = result;
    setNextResult(result);
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult!.balance.placed);
    }
    if (isBaseGameMode(setGameMode())) {
      this.anticipationInfo = this.getAnticipationInfo(this.spinResult);
      this.announceType = this.getAnnounceType(this.nextResult);
      ////const allow = this.announceType != 'None' || this.anticipationInfo.type !== 'None' ? false : true;
      //      const allow = this.announceType != 'None' ? false : true;
      if (this.announceType != 'None') {
        setIsAllowForceReelStop(false);
      }
    }
  }

  public setCollectResult(result: ISettledBet): void {
    console.log(result);
    //TO DO
    eventManager.emit(
      EventTypes.FISHING_SCALING_START,
      getOutcomePredicament(setNextResult()!)?.fishKind!,
      getOutcomePredicament(result!)?.fishingScalingMultiplier!,
    );
  }
  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    setIsSpinShortCut(false);
    setForceReelStop(false);
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.spinResult);
    eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false, setIsAllowForceReelStop());
    this.setState(SlotMachineState.STOP);
  }

  public checkForceReelStop(allow: boolean): void {
    if (setIsSpinShortCut() && allow) {
      if (setGameMode() === GameMode.BASE_GAME) {
        this.stopSpin();
      } else if (setGameMode() === GameMode.FISH_CHALLENGE) {
        eventManager.emit(EventTypes.SET_FISHING_CHALLENGE_TURBO_SPIN);
      }
    }
  }

  public getSlotAt(index: number, x: number, y: number): Slot | null {
    return this.reelsContainers[index]?.reels[x]!.slots[
      (2 * this.reelsContainers[index]!.reels[x]!.data.length -
        this.reelsContainers[index]!.reels[x]!.position +
        y -
        1) %
        this.reelsContainers[index]!.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, 0, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(_width: number, _height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinsMode(setGameMode()) && setIsContinueAutoSpinsAfterFeature()) {
        this.skipWinAnimations();
        const freeSpinsRoundInterval = Tween.createDelayAnimation(
          setIsTurboSpin() ? FREE_SPINS_ROUND_INTERVAL_TURBO : FREE_SPINS_ROUND_INTERVAL,
        );
        freeSpinsRoundInterval.addOnComplete(() => {
          eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
        });
        freeSpinsRoundInterval.start();
      }
      setBrokenGame(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus() != undefined && !isFreeSpinsMode(setGameMode())) {
        const jingleDelay = Tween.createDelayAnimation(JINGLE_TO_WIN_DURATION);
        jingleDelay.addOnStart(() => {
          eventManager.emit(EventTypes.START_FS_WIN_ANIMATION);
          bgmControl.setRestrict(true);
          //bgmControl.fadeOutAll(100);
          //AudioApi.play({ type: ISongs.XT005S_feature_trigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        const stopDuration = PIXI.Loader.shared.resources['symbol_sc2']!.spineData?.findAnimation('stop').duration! * 1;
        const reelStopAnimationDelay = Tween.createDelayAnimation(stopDuration);
        reelStopAnimationDelay.addOnComplete(() => {
          jingleDelay.start();
        });
        reelStopAnimationDelay.start();
      } else if (hasWin(this.nextResult)) {
        const jingleDelay = Tween.createDelayAnimation(
          setIsTurboSpin() ? WIN_ANIM_START_DELAY / 2 : WIN_ANIM_START_DELAY,
        );
        jingleDelay.addOnStart(() => {});
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (hasWin(this.nextResult)) {
        const winDelayDuration = isFreeSpinsMode(setGameMode()) ? 0 : 0;
        const winDelay = Tween.createDelayAnimation(winDelayDuration);
        winDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
        });
        winDelay.start();
      } else {
        if (!isFreeSpinsMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
        }
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
