import * as PIXI from 'pixi.js';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { isFishingChallengeMode } from '../../utils';
import { getFishNameText, getFishRankText } from '../../utils/i18n';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerFishingChallenge } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

import { FishingLayout } from './config';
import { fishingChallengeMeterPortraitTextStyles, fishingChallengeMeterTextStyles } from './textStyle';

class FishMeter extends ViewContainer {
  private base: TickerSpine<'fc_indi'>;
  private mark: TickerSpine<'fc_indi'>;

  private baseContainer: PIXI.Container;

  private textBase: TickerSpine<'fc_indi'>;

  private textTitle: AutoResizeText;
  private textRank: AutoResizeText;
  private textName: AutoResizeText;

  private textBaseContainer: PIXI.Container;
  private wheelCount: number;
  private layout: 'portrait' | 'landscape';

  constructor() {
    super();

    this.layout = 'landscape';
    this.wheelCount = 0;
    this.base = new TickerSpine('fc_indi');
    this.base.state.setAnimation(0, 'base', true);

    this.mark = new TickerSpine('fc_indi');
    this.mark.state.setAnimation(0, 'mark_1', true);
    this.mark.skeleton.setSkinByName('pt01');

    this.baseContainer = new PIXI.Container();
    this.baseContainer.addChild(this.base, this.mark);

    this.textBase = new TickerSpine('fc_indi');
    this.textBase.state.setAnimation(0, 'y_band', true);

    const textStyle = fishingChallengeMeterTextStyles;
    const layout = FishingLayout.Challenge.Meter.landscape;

    this.textTitle = new AutoResizeText(i18n.t('fishing.challenge.meter.title'), textStyle);
    this.textTitle.position.copyFrom(layout.Title);
    this.textTitle.anchor.set(0, 0.5);

    this.textRank = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.rank'), textStyle);
    this.textRank.position.copyFrom(layout.Rank);
    this.textRank.anchor.set(0, 0.5);

    this.textName = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.name'), textStyle);
    this.textName.position.copyFrom(layout.Name);
    this.textName.anchor.set(0, 0.5);

    this.textBaseContainer = new PIXI.Container();
    this.textBaseContainer.addChild(this.textBase, this.textTitle, this.textRank, this.textName);

    this.addChild(this.baseContainer, this.textBaseContainer);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerFishingChallenge;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    const fishRankToSkins: Record<FishKind, SpineInterface['fc_indi']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    if (isFishingChallengeMode(settings.mode)) {
      const skin = settings.fishKind ? fishRankToSkins[settings.fishKind] : 'default';
      this.base.skeleton.setSkinByName(skin);
      this.textBase.skeleton.setSkinByName(skin);
      this.mark.skeleton.setSkinByName(skin);
      this.mark.state.setAnimation(0, 'mark_1', true);
      this.visible = true;

      this.updateText(settings.fishKind!);
    } else {
      this.visible = false;
      this.mark.visible = true;
    }
  }

  private startMoveAnime() {
    switch (this.wheelCount) {
      case 0: {
        this.mark.state.setAnimation(0, 'mark_1to2', false);
        this.mark.state.addAnimation(0, 'mark_2', true);
        break;
      }
      case 1: {
        this.mark.state.setAnimation(0, 'mark_1to2', false);
        this.mark.state.addAnimation(0, 'mark_2', true);
        break;
      }
      case 2: {
        this.mark.state.setAnimation(0, 'mark_2to3', false);
        this.mark.state.addAnimation(0, 'mark_3', true);
        break;
      }
      case 3: {
        this.mark.state.setAnimation(0, 'mark_3togot', false);
        this.mark.state.addAnimation(0, 'mark_got', true);
        break;
      }
      default: {
        break;
      }
    }
  }

  private updateVisible() {
    this.mark.visible = true;
  }

  private updateText(fishRank: FishKind) {
    this.textRank.text = getFishRankText(fishRank);
    this.textName.text = getFishNameText(fishRank);
  }
  public setWinCount(winCount: number) {
    this.wheelCount = winCount;
    this.startMoveAnime();
    //this.updateVisible();
  }

  private setVisible(visible: boolean) {
    this.visible = visible;
  }

  private initText(isPortrait: boolean) {
    this.textBaseContainer.removeChild(this.textTitle, this.textRank, this.textName);

    const textStyle = isPortrait ? fishingChallengeMeterPortraitTextStyles : fishingChallengeMeterTextStyles;
    const layout = isPortrait ? FishingLayout.Challenge.Meter.Portrait : FishingLayout.Challenge.Meter.landscape;

    this.textTitle = new AutoResizeText(i18n.t('fishing.challenge.meter.title'), textStyle);
    this.textTitle.position.copyFrom(layout.Title);
    this.textTitle.anchor.set(0, 0.5);

    this.textRank = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.rank'), textStyle);
    this.textRank.position.copyFrom(layout.Rank);
    this.textRank.anchor.set(0, 0.5);

    this.textName = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.name'), textStyle);
    this.textName.position.copyFrom(layout.Name);
    this.textName.anchor.set(0, 0.5);

    this.textBaseContainer.addChild(this.textTitle, this.textRank, this.textName);
  }

  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? gameLayout.fishMeter.landscape : gameLayout.fishMeter.Portrait;

    if (width > height) {
      this.textBase.state.setAnimation(0, 'y_band', true);

      if (this.layout === 'portrait') {
        this.initText(false);
      }

      this.layout = 'landscape';
    } else {
      this.textBase.state.setAnimation(0, 't_band', true);

      if (this.layout === 'landscape') {
        this.initText(true);
      }
      this.layout = 'portrait';
    }

    this.textBaseContainer.position.copyFrom(layout.textBaseContainer);
    this.baseContainer.position.copyFrom(layout.Container);
    this.baseContainer.scale.set(layout.Container.scale);

    //this.updateVisible();
  };
}

export default FishMeter;
