import { GameMode } from '../../consts';
import Tween from '../animations/tween';
import { AnnounceType } from '../announce/config';
import ViewContainer from '../components/container';
import { layerRod } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

class Rod extends ViewContainer {
  private spine: TickerSpine<'rod'>;

  private isActive: boolean;

  constructor() {
    super();

    this.isActive = false;

    this.spine = new TickerSpine('rod');
    this.addChild(this.spine);

    this.spine.state.setAnimation(0, 'base', true);
    eventManager.addListener(EventTypes.START_ANNOUNCEMENT, this.startAnnounceAnimation.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.reelStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {});

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerRod;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (settings.mode === GameMode.FISH_GAMBLE) {
      this.spine.visible = false;
    } else {
      this.spine.visible = true;
    }
  }

  private startAnnounceAnimation(announceType: AnnounceType) {
    if (this.isActive) return;

    switch (announceType) {
      default:
      case 'None':
      case 'phoenix':
        break;
      case 'low': {
        this.spine.state.setAnimation(0, 'pt01', false);
        this.spine.state.addAnimation(0, 'base', true);
        //AudioApi.play({ type: ISongs.XT005S_anticipation_low, stopPrev: true });
        this.isActive = true;

        const delay = Tween.createDelayAnimation(3446);
        delay.addOnComplete(() => {
          eventManager.emit(EventTypes.END_ANNOUNCEMENT);
        });
        delay.start();
        break;
      }
      case 'high': {
        this.spine.state.setAnimation(0, 'pt02', false);
        this.spine.state.addAnimation(0, 'base', true);
        //AudioApi.play({ type: ISongs.XT005S_anticipation_high, stopPrev: true });
        this.isActive = true;

        const delay = Tween.createDelayAnimation(3888);
        delay.addOnComplete(() => {
          eventManager.emit(EventTypes.END_ANNOUNCEMENT);
        });
        delay.start();
        break;
      }
    }
  }
  private reelStopped(): void {
    /*if (this.isActive) {
      this.spine.state.setAnimation(0, 'base', true);
    }
      */

    if (this.spine.state.getCurrent(0).animation?.name !== 'base') {
      this.spine.state.setAnimation(0, 'base', true);
    }

    this.isActive = false;
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.spine.state.setAnimation(0, 'base', true);
      this.spine.position.copyFrom(gameLayout.rod.landscape);
      this.spine.scale.set(gameLayout.rod.landscape.scale);
    } else {
      this.spine.state.setAnimation(0, 'base', true);
      this.spine.position.copyFrom(gameLayout.rod.portrait);
      this.spine.scale.set(gameLayout.rod.portrait.scale);
    }
  };
}

export default Rod;
