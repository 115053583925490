import { BonusStatus, GameMode } from '../../consts';
import { getFragmentData } from '../../gql';
import {
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { wagerFragment } from '../../gql/fragment';
import { getRestoreStateGql } from '../../gql/query';
import { IWagerFragment } from '../../types';
import { getNonNullableValue, getWagerSettingsFromWager, getWagerStorageFromWager } from '../../utils';

//TO DO
export const useRestoreState = () => {
  const checkBrokenGame = async () => {
    const { data: restoreStateData } = await client.query({
      query: getRestoreStateGql,
      variables: { input: { slotId: setSlotConfig().slotId } },
      fetchPolicy: 'network-only',
    });

    if (restoreStateData.restoreState.wagers.length > 0) {
      const wagers = getFragmentData(
        wagerFragment,
        getNonNullableValue(restoreStateData.restoreState.wagers),
      ) as IWagerFragment[];
      const buyFeatureBonus = wagers.find((wager) => {
        return 'gameMode' in wager.wagerSettings && wager.wagerSettings.gameMode === GameMode.BUY_FEATURE
          ? wager
          : undefined;
      }) as IWagerFragment;
      const freeSpinsBonus = wagers.find((wager) => {
        return 'gameMode' in wager.wagerSettings && wager.wagerSettings.gameMode === GameMode.FREE_SPINS
          ? wager
          : undefined;
      });

      const wagerStorage = getNonNullableValue(getWagerStorageFromWager(buyFeatureBonus));
      const wagerSettings = getNonNullableValue(getWagerSettingsFromWager(buyFeatureBonus));

      if (!wagerSettings) return;
      if (!wagerStorage) return;

      if (buyFeatureBonus) {
        //TO DO : if buybonus has multi id
        setBrokenBuyFeatureGame(wagerSettings.packageId!);
        setIsBuyFeaturePopupOpened(true);
        setCurrentBonus({
          packageId: wagerSettings.packageId,
          gameMode: wagerSettings.gameMode,
          rounds: wagerSettings.rounds,
          roundsPlayed: wagerStorage.roundsPlayed,
          state: buyFeatureBonus.state as BonusStatus,
          coinAmount: buyFeatureBonus.coinAmount!,
          coinValue: buyFeatureBonus.coinValue!,
          originalReelPositions: wagerSettings.originalReelPositions,
          isBuyFeature: true,
        });
      } else if (freeSpinsBonus) {
        setBrokenGame(true);
        setCurrentFreeSpinsTotalWin(wagerStorage.previousTotalWinCoinAmount + wagerStorage.totalWinCoinAmount);
        setCurrentBonus({
          packageId: wagerSettings.packageId,
          gameMode: wagerSettings.gameMode as GameMode,
          rounds: wagerSettings.rounds,
          roundsPlayed: wagerStorage.roundsPlayed,
          state: freeSpinsBonus.state as BonusStatus,
          coinAmount: freeSpinsBonus.coinAmount!,
          coinValue: freeSpinsBonus.coinValue!,
          originalReelPositions: wagerSettings.originalReelPositions,
          isBuyFeature: wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
        });
      }
    } else if (restoreStateData.restoreState.bets[0]) {
      /*
    } else if (restoreStateData.restoreState.bets[0] && restoreStateData.restoreState.bets[0]?.outcomes.length >= 5) {
      // TODO 判定見直し必要かも　outcomes.status = OPENならでも行けそう
      setBrokenGambleGame(true);

      setNextResult({
        bet: {
          betStorage: restoreStateData.restoreState.bets[0].betStorage,
          id: restoreStateData.restoreState.bets[0].id,
          slotId: restoreStateData.restoreState.bets[0].slotId,
          playerId: restoreStateData.restoreState.bets[0].playerId,
          wager: restoreStateData.restoreState.bets[0].wager,
          status: restoreStateData.restoreState.bets[0].status,
          outcomes: restoreStateData.restoreState.bets[0].outcomes,
          coinAmount: restoreStateData.restoreState.bets[0].coinAmount,
          effectiveCoinAmount: restoreStateData.restoreState.bets[0].effectiveCoinAmount,
          coinValue: restoreStateData.restoreState.bets[0].coinValue,
          createdAt: restoreStateData.restoreState.bets[0].createdAt,
          spinResult: [
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
          ],
        },
        balance: {
          settled: {
            amount: 0,
            currency: '0',
          },
          placed: {
            amount: 0,
            currency: '0',
          },
        },
        winCoinAmount: 0,
      });
    }
    */
    }
  };

  return {
    checkBrokenGame,
  };
};
