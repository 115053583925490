import { GameMode } from '../../../consts';
import { ANNOUNCE_REEL_ALPHA } from '../../announce/config';
import ViewContainer from '../../components/container';
import { layerReelFrame } from '../../components/layers/layers';
import { TickerSpine } from '../../components/spine';
import {
  EventTypes,
  SLOTS_REELFRAME_HEIGHT,
  SLOTS_REELFRAME_WIDTH,
  SLOTS_REELFRAME_X,
  SLOTS_REELFRAME_Y,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  //private frame_b: TickerSpine<'reelframe'>;
  private frame_f: TickerSpine<'reelframe'>;

  constructor() {
    super();
    //this.frame_b = new TickerSpine('reelframe');
    //this.frame_b.position.set(
    //  SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
    //   SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    // );
    // this.frame_b.state.setAnimation(0, 'back', true);
    // this.frame_b.parentLayer = layerReelBack;

    this.frame_f = new TickerSpine('reelframe');
    this.frame_f.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.frame_f.state.setAnimation(0, 'base', true);
    this.frame_f.parentLayer = layerReelFrame;

    this.addChild(this.frame_f);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.START_ANNOUNCEMENT, () => {
      this.alpha = ANNOUNCE_REEL_ALPHA;
    });
    eventManager.addListener(EventTypes.END_ANNOUNCEMENT, () => {
      this.alpha = 1.0;
    });
  }
  private onChangeMode(_settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    this.frame_f.parentLayer = layerReelFrame;
  }
}

export default ReelsFrame;
