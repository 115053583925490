import { SlotId } from '../../config';
import { AnticipationInfo, AnticipationReelType } from '../../global.d';
import { setForceReelStop } from '../../gql/cache';
import { ReelSet } from '../../types';
import { isBuyFeatureMode } from '../../utils';
import Tween from '../animations/tween';
import { ANNOUNCE_REEL_ALPHA, AnnounceReelRollingExternalDuration, AnnounceType } from '../announce/config';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_BONUS_ENDING_DURATION,
  ANTICIPATION_BONUS_ENDING_SLOTS_AMOUNT,
  ANTICIPATION_REEL_FORMULA,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_SPIN_TIME,
  EventTypes,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  REELS_AMOUNT,
  REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT,
  REEL_BUYFEATURE_MYSTERY_ENDING_DURATION,
  REEL_BUYFEATURE_ROLLING_DURATION,
  REEL_ENDING_SLOTS_AMOUNT,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SOUND_REEL_STOP_SOUND_PRIORITY,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';

import { REELS_SETTING } from './config';
import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  public index: number;

  constructor(reels: SlotId[][], startPosition: number[], index: number) {
    super();
    this.index = index;
    this.initContainer();
    this.initReels(reels, startPosition);
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, () => {
      this.hideSlots();
    });
    eventManager.addListener(EventTypes.REEL_STOPPED, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReelsBranching.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));

    eventManager.addListener(EventTypes.START_ANNOUNCEMENT, () => {
      this.alpha = ANNOUNCE_REEL_ALPHA;
    });
    eventManager.addListener(EventTypes.END_ANNOUNCEMENT, () => {
      this.alpha = 1.0;
    });

    this.parentLayer = REELS_SETTING[index]?.parentLayer!;
  }

  private hideSlots(_reelId?: number): void {
    const arr = [];
    arr.push(0);
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(_reelId?: number): void {
    const arr = [];
    arr.push(0);
    this.setSlotsVisibility(arr, true);
  }

  //TO DO
  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getStartingDelay() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getStarting() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFirstRolling() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFakeRolling() as Tween);
      this.reels[i]!.position = this.reels[i]!.size - positions[i]!;
      this.reels[i]!.state = ReelState.IDLE;
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;

    //reference point
    this.x = 0;
    this.y = 0;
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    const reelLayout = settings.reelSet.layout.map((reel) =>
      reel.length < SLOTS_PER_REEL_AMOUNT + 1 ? [...reel, ...reel] : reel,
    );

    const reelPositions = settings.reelPositions
      .slice(0, REELS_AMOUNT)
      .map((position, idx) => (reelLayout[idx]!.length - position) % reelLayout[idx]!.length);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i]!.clean();
      this.reels[i]!.init(reelLayout[i]!, reelPositions[this.index]!);
    }
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    reels = reels.map((reel) => (reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel));

    //for (let i = 0; i < REELS_AMOUNT; i++) {
    const position = startPosition ? startPosition[this.index]! : 0;
    const reel = new Reel(this.index, reels[this.index]!, position);
    this.reels[0] = reel;
    this.addChild(reel.container);

    eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    //}
  }

  private forceStopReelsBranching(isTurboSpin: boolean, allowForceStop?: boolean): void {
    if (allowForceStop) {
      this.forceStopReels(isTurboSpin);
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.alpha = 1.0;
    this.forcedStop = true;
    setForceReelStop(true);
    const startTime = this.reels[0]!.spinAnimation ? this.reels[0]!.spinAnimation!.startTime : 0;
    const stopAllReelsAtSameTime = Date.now() - startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);

    //TO DO
    if (stopAllReelsAtSameTime) {
      let [maxSoundPrio, maxSoundIdx] = [0, 0];
      for (let i = 0; i < this.reels.length; i++) {
        const prio = SOUND_REEL_STOP_SOUND_PRIORITY[this.reels[i]!.stopSoundType];

        if (prio > maxSoundPrio) {
          [maxSoundPrio, maxSoundIdx] = [prio, i];
        }
        this.reels[i]!.isPlaySoundOnStop = false;
      }
      this.reels[maxSoundIdx]!.isPlaySoundOnStop = true;
    }

    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i]!.stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setAnimationfromCalculatedTarget(
    rollingAnimation: Tween,
    endingAnimation: Tween,
    target: number,
    endingSlotAmount: number,
    rollingSpeed: number,
    reelIdx: number,
  ): void {
    let beginValue = target - endingSlotAmount - Math.round(rollingAnimation.duration * rollingSpeed);
    if (beginValue < 0) {
      const prolong = this.prolongTarget(this.reels[reelIdx]!, Math.abs(beginValue));
      beginValue += prolong;
      target += prolong;
    }
    rollingAnimation.propertyBeginValue = beginValue;
    rollingAnimation.target = target - endingSlotAmount;

    endingAnimation.propertyBeginValue = target - endingSlotAmount;
    endingAnimation.target = target;
  }

  private setNextReelAnticipationAnimation(
    _anticipationInfo: AnticipationInfo,
    _reelIdx: number,
    _nextReelIdx: number,
  ) {
    /*
    const nextStopSlotIds =
      anticipationInfo.reels[nextReelIdx]?.type === 'Bonus'
        ? [SlotId.SC]
        : anticipationInfo.reels[nextReelIdx]?.type === 'Snow'
        ? [SlotId.SC]
        : [];
    if (nextStopSlotIds.includes(SlotId.SC) || nextStopSlotIds.includes(SlotId.SC)) {
      if (!this.forcedStop) {
        eventManager.emit(EventTypes.ANTICIPATION_STOPPED_SLOT_ANIMATIONS_START, nextStopSlotIds, reelIdx);
      }
    }
      */
  }

  private anticipationAnimationStartCheck(
    anticipationReelInfo: AnticipationReelType | undefined,
    durationType: number,
  ) {
    if (anticipationReelInfo?.type === 'Bonus' || anticipationReelInfo?.type === 'BonusG') {
      eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_START, durationType);
    }
  }

  private anticipationAnimationEndCheck(_anticipationInfo: AnticipationInfo, _reelIdx: number, _isRespin: boolean) {}

  private setupBaseGameAnimationTarget(
    reelPositions: number[],
    anticipationInfo: AnticipationInfo,
    announceType: AnnounceType,
  ): void {
    const rollingSpeed = this.reels[0]!.isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    let preAnticipationDuration = 0;

    //for (let j = 0; j < this.reels.length; j++) {
    const j = 0;
    const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
    const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
    const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
    const target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.index]!);
    const anticipationReelInfo = anticipationInfo.reels[this.index];

    let anticipationDurationType = 0;

    fakeRollingAnimation.duration = 0;
    this.reels[j]!.stopSoundType = anticipationInfo.reels[this.index]!.soundType;
    this.reels[j]!.anticipationInfo = anticipationInfo.reels[this.index]!;

    rollingAnimation.duration += AnnounceReelRollingExternalDuration[announceType];

    if (anticipationReelInfo?.type === 'Bonus' || anticipationReelInfo?.type === 'BonusG') {
      const endingDurationTbl = ANTICIPATION_BONUS_ENDING_DURATION;

      const endingAmoutTbl = ANTICIPATION_BONUS_ENDING_SLOTS_AMOUNT;

      anticipationDurationType = Math.floor(Math.random() * endingDurationTbl.length);
      const endingSlotAmount = endingAmoutTbl[anticipationDurationType]!;
      const anticipation_duration = endingDurationTbl[anticipationDurationType]!;
      rollingAnimation.duration += preAnticipationDuration;
      this.setAnimationfromCalculatedTarget(
        rollingAnimation,
        endingAnimation,
        target,
        endingSlotAmount,
        rollingSpeed,
        j,
      );

      endingAnimation.duration = BASE_REEL_ENDING_DURATION;
      endingAnimation.duration += anticipation_duration;
      endingAnimation.easing = ANTICIPATION_REEL_FORMULA;

      preAnticipationDuration += endingAnimation.duration;
    } else {
      rollingAnimation.duration += preAnticipationDuration;
      this.setAnimationfromCalculatedTarget(
        rollingAnimation,
        endingAnimation,
        target,
        REEL_ENDING_SLOTS_AMOUNT,
        rollingSpeed,
        j,
      );
    }

    endingAnimation.addOnStart(() => {
      this.alpha = 1.0;
      this.anticipationAnimationStartCheck(anticipationReelInfo, anticipationDurationType);
    });
    endingAnimation.addOnComplete(() => {});
  }

  //TO DO
  private setupBuyFeatureAnimationTarget(reelPositions: number[]): void {
    //    const rollingSpeed = BASE_REEL_ROLLING_SPEED;
    const isTurboSpin = this.reels[0]!.isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;

    const endingAnimationType = 0;
    const endingSlotAmount = REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT[endingAnimationType]!;
    const endingAnimationDuration = isTurboSpin
      ? REEL_BUYFEATURE_MYSTERY_ENDING_DURATION[endingAnimationType]! / 2
      : REEL_BUYFEATURE_MYSTERY_ENDING_DURATION[endingAnimationType]!;

    //    this.reels[0]!.stopSoundType = 'bonus';
    if (this.index === 3) {
      this.reels[0]!.anticipationInfo.soundType = 'bonus';
    }

    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
      const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
      let target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.index]!);

      fakeRollingAnimation.duration = 0;
      rollingAnimation.duration = this.reels[0]!.isTurboSpin
        ? REEL_BUYFEATURE_ROLLING_DURATION / 2
        : REEL_BUYFEATURE_ROLLING_DURATION;

      let beginValue = target - endingSlotAmount - Math.round(rollingAnimation.duration * rollingSpeed);
      if (beginValue < 0) {
        const prolong = this.prolongTarget(this.reels[j]!, Math.abs(beginValue));
        beginValue += prolong;
        target += prolong;
      }
      rollingAnimation.propertyBeginValue = beginValue;
      rollingAnimation.target = target - endingSlotAmount;

      endingAnimation.duration += endingAnimationDuration;
      endingAnimation.propertyBeginValue = target - endingSlotAmount;
      endingAnimation.target = target;
      endingAnimation.addOnComplete(() => {});
    }
  }

  private setupAnimationTarget(
    reelPositions: number[],
    anticipationInfo: AnticipationInfo,
    announceType: AnnounceType,
  ): void {
    if (announceType !== 'None') {
      eventManager.emit(EventTypes.START_ANNOUNCEMENT, announceType);
    }

    if (announceType === 'phoenix') {
      eventManager.emit(EventTypes.PHOENIX_START);
    }

    if (isBuyFeatureMode()) {
      this.setupBuyFeatureAnimationTarget(reelPositions);
    } else {
      this.setupBaseGameAnimationTarget(reelPositions, anticipationInfo, announceType);
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((_slotId) => {
      const x = 0;
      const y = 0;
      const reel = this.reels[x];
      if (!reel) return;

      const position = reel.size - (Math.round(reel.position) % reel.size) + y;
      const normalizedPosition = position === -1 ? reel.size - 1 : position % reel.size;
      const slot = reel.slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
